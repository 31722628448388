import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingUiBlockerComponent } from './loading-ui-blocker.component';

@NgModule({
  declarations: [LoadingUiBlockerComponent],
  imports: [CommonModule, ProgressSpinnerModule],
  exports: [LoadingUiBlockerComponent],
})
export class LoadingUiBlockerModule {}
