<p-dialog
  [(visible)]="presenter.visible"
  [resizable]="false"
  [draggable]="false"
  [modal]="true"
  [blockScroll]="true"
  (onHide)="onCancel()"
  styleClass="p-dialog--maxWidth80Percent timezoneDialog"
>
  <ng-template pTemplate="header">
    {{ 'MENU__TIMEZONE' | translate }}
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngTemplateOutlet="dialogContent"></ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <ng-container *ngTemplateOutlet="dialogFooter"></ng-container>
  </ng-template>
</p-dialog>

<ng-template #dialogContent>
  <form class="mb-4" [formGroup]="formGroup">
    <section class="timezoneDialog__formGroup">
      <label>{{ 'TIMEZONE__PREFERRED_TIMEZONE' | translate }}</label>
      <p-dropdown
        class="formControl"
        formControlName="settingValue"
        optionLabel="name"
        optionValue="code"
        placeholder=" "
        [options]="presenter.timezones$ | async"
      ></p-dropdown>
    </section>
  </form>
</ng-template>

<ng-template #dialogFooter>
  <button type="button" class="btn btn-outline-primary" (click)="onCancel()">
    {{ 'BUTTON__CANCEL' | translate }}
  </button>
  <button class="btn btn-primary" (click)="onSave()">
    {{ 'BUTTON__SAVE' | translate }}
  </button>
</ng-template>
