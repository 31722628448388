<p-dialog
  [(visible)]="presenter.visible"
  [resizable]="false"
  [draggable]="false"
  [modal]="true"
  [blockScroll]="true"
  (onHide)="onClose()"
  styleClass="p-dialog--maxWidth80Percent viewAnnouncementDialog"
>
  <ng-template pTemplate="header">
    <div>
      {{ 'MENU__ANNOUNCEMENT' | translate }}
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngTemplateOutlet="dialogContent"></ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <ng-container *ngTemplateOutlet="dialogFooter"></ng-container>
  </ng-template>
</p-dialog>

<ng-template #dialogContent>
  <header class="mb-1">
    <b>{{ (presenter.announcement$ | async)?.title }}</b>
  </header>
  <section class="mb-3">
    {{ (presenter.announcement$ | async)?.description }}
  </section>
</ng-template>

<ng-template #dialogFooter>
  <button type="button" class="btn btn-outline-primary" (click)="onClose()">
    {{ 'BUTTON__CLOSE' | translate }}
  </button>
</ng-template>
