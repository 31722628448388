import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { UserService } from '@services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsResolver implements Resolve<boolean> {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService
  ) {}

  async resolve(): Promise<boolean> {
    try {
      const userDetails = await this.userService.getCurrentUserDetails();
      return !!userDetails;
    } catch (error) {
      const errorMessage = error?.error?.Message || error.message;
      this.authenticationService.removeAccessToken();
      this.router.navigateByUrl(`/error?errorMessage=${errorMessage}`);
      throw error;
    }
  }
}
