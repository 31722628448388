import { Injectable } from '@angular/core';

@Injectable()
export class HelpDialogService {
  visible = false;

  constructor() {}

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }
}
