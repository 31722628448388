import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationUserApiService } from '@api/application-user/application-user.api';
import { CurrentUserDetails } from '@models/user';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@services/language/language.service';
import { TimezoneService } from '@services/timezone/timezone.service';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private _userDetails = new BehaviorSubject<CurrentUserDetails>(null);
  userDetails$ = this._userDetails.asObservable();

  get userDetails(): CurrentUserDetails {
    return this._userDetails.value;
  }

  constructor(
    private applicationUserApi: ApplicationUserApiService,
    private languageService: LanguageService,
    private timezoneService: TimezoneService,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnDestroy(): void {
    // Reserve for `untilDestroyed()` operator.
  }

  async getCurrentUserDetails(): Promise<CurrentUserDetails> {
    const title = this.translateService.instant(
      'SETTINGS__FAILED_TO_GET_CURRENT_USER_DETAILS'
    );

    try {
      const response = await this.applicationUserApi
        .currentUserDetails()
        .toPromise();
      const userDetails = response.data;

      if (!userDetails) {
        return userDetails;
      }

      this._userDetails.next(userDetails);

      if (userDetails.timezoneOffset && userDetails.timezone) {
        this.timezoneService.setTimezone({
          offset: userDetails.timezoneOffset,
          area: userDetails.timezone,
        });
      }

      const currentLanguage = this.languageService.getLanguage();

      if (userDetails.language && userDetails.language !== currentLanguage) {
        this.languageService.setLanguage(userDetails.language);
        await this.translateService
          .use(userDetails.language)
          .toPromise()
          .catch((error) => {
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant(
                'LANGUAGE__FAILED_TO_LOAD_LANGUAGE'
              ),
              detail: error?.error?.Message || error.message,
            });
          });
      }

      return userDetails;
    } catch (error: any) {
      this.messageService.add({
        severity: 'error',
        summary: title,
        detail: error?.error?.Message || error.message,
      });
      throw error;
    }
  }
}
