import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApplicationUserApiService } from '@api/application-user/application-user.api';
import { AuthenticationService } from '@services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticateUserGuard implements CanActivate {
  constructor(
    protected authenticationService: AuthenticationService,
    protected applicationUserApi: ApplicationUserApiService,
    protected router: Router
  ) {}

  async canActivate(): Promise<boolean> {
    try {
      const isAuthenticated = this.authenticationService.isAuthenticated;

      if (isAuthenticated) {
        return true;
      }

      const loginUrl = await this.applicationUserApi.login().toPromise();
      window.location.replace(loginUrl);

      return false;
    } catch (error) {
      const url = `/error?errorMessage=Login failed. Please contact administrator.`;
      this.router.navigateByUrl(url);
      return false;
    }
  }
}
