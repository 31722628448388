import { Injectable } from '@angular/core';
import { Announcement } from '@models/announcement';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AnnouncementService } from '../../services/announcement.service';

@Injectable()
export class AnnouncementDialogService {
  private announcement = new BehaviorSubject<Announcement>(null);
  announcement$ = this.announcement.asObservable();

  publishedAnnouncement$ = this.service.publishedAnnouncement$;

  visible = false;

  private saveSubscription: Subscription;

  constructor(
    private service: AnnouncementService,
    private messsageService: MessageService,
    private translateService: TranslateService
  ) {}

  open(): void {
    this.service
      .get()
      .pipe(tap(() => this.announcement.next(this.service.announcement)))
      .subscribe();
    this.visible = true;
  }

  close(): void {
    this.reset();
    this.visible = false;
  }

  save(announcement: Announcement): void {
    if (this.saveSubscription && !this.saveSubscription.closed) {
      this.messsageService.add({
        severity: 'warn',
        summary: this.translateService.instant('MESSAGE__SAVING_IN_PROGRESS'),
      });
      return;
    }

    if (
      !announcement.previousAnnouncementId &&
      this.service.publishedAnnouncement
    ) {
      announcement.previousAnnouncementId =
        this.service.publishedAnnouncement.id;
    }
    announcement.isActive = true;

    this.saveSubscription = this.service
      .save(announcement)
      .pipe(tap(() => this.close()))
      .subscribe();
  }

  private reset(): void {
    this.saveSubscription = null;
    this.announcement.next(null);
  }
}
