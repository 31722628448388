import { Component, Inject } from '@angular/core';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { LogoutCountdownDialogService } from './logout-countdown-dialog.service';

@Component({
  selector: 'app-logout-countdown-dialog',
  templateUrl: './logout-countdown-dialog.component.html',
  styleUrls: ['./logout-countdown-dialog.component.scss'],
})
export class LogoutCountdownDialogComponent {
  idleTimeoutInHours = Math.floor(
    this.envConfig.environment.idlePeriodInSeconds / 3600
  );
  idleTimeoutInMinutes = Math.floor(
    (this.envConfig.environment.idlePeriodInSeconds % 3600) / 60
  );
  idleTimeoutInSeconds = this.envConfig.environment.idlePeriodInSeconds % 3600;

  constructor(
    @Inject(ENV_CONFIG)
    public envConfig: EnvironmentConfig,
    public presenter: LogoutCountdownDialogService
  ) {}

  onStay(): void {
    this.presenter.stay();
  }

  onLogout(): void {
    this.presenter.confirmLogout();
  }

  onCancel(): void {
    this.presenter.stay();
  }
}
