import { Injectable, OnDestroy } from '@angular/core';
import { CodeTableApiService } from '@api/code-table/code-table.api';
import { UserSettingApiService } from '@api/user-setting/user-setting.api';
import { KeyValuePair, Pagination } from '@models/api';
import { UserSetting, UserSettingKey } from '@models/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@services/language/language.service';
import { UserService } from '@services/user/user.service';
import { promptMessageOnError } from '@utils/rxjs/prompt-message-on-error';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

const DEFAULT_PAGINATION: Pagination = {
  current: 1,
  pageSize: 1000,
  total: null,
};

@UntilDestroy()
@Injectable()
export class LanguageDialogService implements OnDestroy {
  private languages = new BehaviorSubject<KeyValuePair[]>([]);
  languages$ = this.languages.asObservable();

  private language = new BehaviorSubject<UserSetting>(null);
  language$ = this.language.asObservable();

  visible = false;

  private getSubscription: Subscription;
  private saveSubscription: Subscription;

  constructor(
    private api: UserSettingApiService,
    private codeTableApi: CodeTableApiService,
    private languageService: LanguageService,
    private messsageService: MessageService,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    this.getLanguages();
  }

  ngOnDestroy(): void {
    // Reserve for `untilDestroyed()` operator.
  }

  open(): void {
    this.getLanguage();
    this.visible = true;
  }

  close(): void {
    this.reset();
    this.visible = false;
  }

  save(language: UserSetting): void {
    if (this.saveSubscription && !this.saveSubscription.closed) {
      return;
    }

    if (this.language.value?.settingValue === language.settingValue) {
      this.close();
      return;
    }

    const title = this.translateService.instant('LANGUAGE__FAILED_TO_UPDATE');

    language.userId = this.userService.userDetails.id;
    language.settingKey = UserSettingKey.LANGUAGE;
    language.dataType = 'string';

    this.createOrUpdateLanguage(language)
      .pipe(
        tap(() =>
          this.messsageService.add({
            severity: 'success',
            summary: this.translateService.instant(
              'LANGUAGE__UPDATE_SUCCESSFUL'
            ),
          })
        ),
        tap(() => this.languageService.setLanguage(language.settingValue)),
        tap(() => this.close()),
        delay(800),
        tap(() => window.location.reload()),
        promptMessageOnError(this.messsageService, title),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getLanguage(): void {
    const userId = this.userService.userDetails.id;
    const title = this.translateService.instant(
      'LANGUAGE__FAILED_TO_GET_LANGUAGE'
    );
    this.getSubscription = this.api
      .get(userId, UserSettingKey.LANGUAGE)
      .pipe(
        map(({ data }) => data),
        tap((language) => this.language.next(language)),
        promptMessageOnError(this.messsageService, title),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getLanguages(): void {
    const title = this.translateService.instant(
      'LANGUAGE__FAILED_TO_GET_LANGUAGES'
    );
    this.codeTableApi
      .getLanguages(null, DEFAULT_PAGINATION)
      .pipe(
        map(({ data }) => data.data),
        tap((languages) => this.languages.next(languages)),
        promptMessageOnError(this.messsageService, title),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private createOrUpdateLanguage(language: UserSetting): Observable<any> {
    if (language.id) {
      return this.api.update(language);
    }
    return this.api.create(language);
  }

  private reset(): void {
    if (this.getSubscription && !this.getSubscription.closed) {
      this.getSubscription.unsubscribe();
    }

    if (this.saveSubscription && !this.saveSubscription.closed) {
      this.saveSubscription.unsubscribe();
    }

    this.getSubscription = null;
    this.saveSubscription = null;
    this.language.next(null);
  }
}
