<p-overlayPanel styleClass="notificationOverlayPanel">
  <ng-template pTemplate>
    <header class="notificationOverlayPanel__header">
      <span class="notificationOverlayPanel__headerContent">
        {{ 'NOTIFICATION' | translate }}
      </span>
    </header>
    <main>
      <ng-container
        *ngTemplateOutlet="
          announcement || (notifications && notifications.length > 0)
            ? content
            : noContent
        "
      ></ng-container>
    </main>
  </ng-template>
</p-overlayPanel>

<ng-template #content>
  <section
    class="notificationOverlayPanel__content"
    *ngIf="announcement"
    (click)="onAnnouncementClick(announcement)"
  >
    <div class="notificationOverlayPanel__contentHeader">
      {{ announcement.title }}
    </div>
    <div class="notificationOverlayPanel__contentMessage">
      {{ (announcement.description | slice: 0:199)?.trim()
      }}<span *ngIf="announcement.description?.length > 200"
        >...
        <span class="notificationOverlayPanel__readMore">
          {{ 'GENERIC__READ_MORE' | translate }}
        </span>
      </span>
    </div>
    <div class="notificationOverlayPanel__contentMessageDate">
      {{
        announcement.updateDate || announcement.createDate
          | relativeDate: timezoneService.offset
      }}
    </div>
  </section>
  <section
    class="notificationOverlayPanel__content"
    *ngFor="let notification of notifications; let index = index"
    [hidden]="isShowAllNotification ? false : index > 1"
    (click)="onNotificationClick(notification)"
  >
    <div class="notificationOverlayPanel__contentHeader">
      {{ notification.title }}
    </div>
    <div class="notificationOverlayPanel__contentMessage">
      {{ notification.description }}
    </div>
    <div class="notificationOverlayPanel__contentMessageDate">
      {{ notification.notificationDate | relativeDate: timezoneService.offset }}
    </div>
  </section>
  <section
    class="notificationOverlayPanel__readAll"
    *ngIf="notifications && notifications.length > 2 && !isShowAllNotification"
    (click)="onReadAllNotification()"
  >
    {{ 'NOTIFICATION__READ_ALL_NOTIFICATION' | translate }}
  </section>
</ng-template>

<ng-template #noContent>
  <div class="notificationOverlayPanel__noContentMessage">
    {{ 'NOTIFICATION__NO_NOTIFICATION_AND_ANNOUNCEMENT' | translate }}
  </div>
</ng-template>
