import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse, PaginationResult } from '@models/api';
import { GetJabilUserSearchCriteria } from '@models/api/jabil-user';
import { Pagination, SortEvent } from '@models/table';
import {
  AccessRight,
  CurrentUserDetails,
  User,
  UserListing,
} from '@models/user';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { HttpParamsGenerator } from '@utils/http/http-params-generator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApplicationUserApiService {
  private apiUrl = `${this.envConfig.environment.baseUrl}/api/application-user`;

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {}

  login(): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/login`);
  }

  logout(): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/logout`);
  }

  getAll(
    pagination: Pagination,
    sort: SortEvent<UserListing>,
    searchCriteria: GetJabilUserSearchCriteria
  ): Observable<PaginationResult<UserListing[]>> {
    const value = {
      ...pagination,
      ...searchCriteria,
      sorting: [sort],
    };
    const params = new HttpParamsGenerator(value).generate();
    return this.http.get<PaginationResult<UserListing[]>>(`${this.apiUrl}`, {
      params,
    });
  }

  getOne(id: number): Observable<ApiResponse<User>> {
    return this.http.get<ApiResponse<User>>(`${this.apiUrl}/${id}`);
  }

  create(user: User): Observable<ApiResponse<User>> {
    return this.http.post<ApiResponse<User>>(this.apiUrl, user);
  }

  update(user: User): Observable<ApiResponse<User>> {
    return this.http.put<ApiResponse<User>>(this.apiUrl, user);
  }

  currentUserDetails(): Observable<ApiResponse<CurrentUserDetails>> {
    const url = `${this.apiUrl}/current`;
    return this.http.get<ApiResponse<CurrentUserDetails>>(url);
  }

  currentAccessRights(
    pagination: Pagination,
    sort: SortEvent<AccessRight>,
    keyword: string
  ): Observable<PaginationResult<AccessRight[]>> {
    const value = {
      ...pagination,
      sorting: [sort],
      keyword,
    };
    const params = new HttpParamsGenerator(value).generate();
    const url = `${this.apiUrl}/access-right`;
    return this.http.get<PaginationResult<AccessRight[]>>(url, { params });
  }
}
