import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '@services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class IsGuestUserGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authenticationService.isAuthenticated === false) {
      return true;
    }

    if (route.queryParams?.redirect) {
      window.location.href = route.queryParams.redirect;
      return false;
    }

    this.router.navigate(['']);
  }
}
