import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { KeyValuePair } from '@models/api';
import { User } from '@models/user';
import { TimezoneService } from '@services/timezone/timezone.service';
import { WEBSITE_URL_REGEX } from '@utils/regex';
import { DateRangeValidator } from '@utils/validators/date-range-validator';
import { PhoneNumberValidator } from '@utils/validators/phone-number-validator';
import { format, setYear } from 'date-fns';
import { toDate, utcToZonedTime } from 'date-fns-tz';
import { Controls, DataInput, NgxRootFormComponent } from 'ngx-sub-form';

interface UserForm {
  id: number;
  userName: string;
  name: string;
  email: string;
  phoneNumber: string;
  countryId: number;
  preferredLanguageId: number;
  companyName: string;
  companyWebsite: string;
  postalCode: string;
  effectiveDates: [Date, Date];
  oktaVerified: boolean;
  roleId: number;
}

@Component({
  selector: 'app-jabil-user-form',
  templateUrl: './jabil-user-form.component.html',
  styleUrls: ['./jabil-user-form.component.scss'],
})
export class JabilUserFormComponent extends NgxRootFormComponent<
  User,
  UserForm
> {
  // tslint:disable-next-line: no-input-rename
  @DataInput()
  @Input('user')
  dataInput: User;

  @Input()
  countries: KeyValuePair[];

  @Input()
  languages: KeyValuePair[];

  @Input()
  isEditMode = false;

  @Input()
  isEditSelf = false;

  @Output()
  dataOutput: EventEmitter<User>;

  get user(): User {
    return this.dataValue;
  }

  constructor(private timezoneService: TimezoneService) {
    super();
  }

  protected getDefaultValues(): UserForm {
    return {
      id: null,
      companyName: null,
      companyWebsite: null,
      countryId: null,
      effectiveDates: [new Date(), setYear(new Date(), 2099)],
      email: null,
      name: null,
      phoneNumber: null,
      postalCode: null,
      preferredLanguageId: null,
      userName: null,
      oktaVerified: false,
      roleId: null,
    };
  }

  protected getFormControls(): Controls<UserForm> {
    return {
      id: new FormControl(null),
      userName: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [
        Validators.required,
        PhoneNumberValidator,
      ]),
      preferredLanguageId: new FormControl(null, Validators.required),
      companyName: new FormControl('', Validators.required),
      companyWebsite: new FormControl(
        '',
        Validators.pattern(WEBSITE_URL_REGEX)
      ),
      postalCode: new FormControl(''),
      countryId: new FormControl(null, Validators.required),
      effectiveDates: new FormControl(null, [
        Validators.required,
        DateRangeValidator,
      ]),
      oktaVerified: new FormControl(false),
      roleId: new FormControl(null, Validators.required),
    };
  }

  protected transformFromFormGroup(userForm: UserForm): User {
    const timeZone = this.timezoneService.offset;
    const effectiveEndDate =
      userForm.effectiveDates && userForm.effectiveDates[1]
        ? toDate(format(userForm.effectiveDates[1], 'yyyy-MM-dd 23:59:59'), {
            timeZone,
          })
        : null;
    const effectiveStartDate =
      userForm.effectiveDates && userForm.effectiveDates[0]
        ? toDate(format(userForm.effectiveDates[0], 'yyyy-MM-dd 00:00:00'), {
            timeZone,
          })
        : null;

    return {
      id: userForm.id,
      companyName: userForm.companyName,
      companyWebsite: userForm.companyWebsite,
      countryId: userForm.countryId,
      effectiveEndDate,
      effectiveStartDate,
      email: userForm.email,
      name: userForm.name,
      oktaVerified: userForm.oktaVerified,
      phoneNumber: userForm.phoneNumber,
      postalCode: userForm.postalCode,
      preferredLanguageId: userForm.preferredLanguageId,
      roleId: userForm.roleId,
      userName: userForm.userName,
    };
  }

  protected transformToFormGroup(user: User): UserForm {
    const timeZone = this.timezoneService.timezone.offset;
    const effectiveStartDate = user.effectiveStartDate
      ? utcToZonedTime(new Date(user.effectiveStartDate), timeZone)
      : null;
    const effectiveEndDate = user.effectiveEndDate
      ? utcToZonedTime(new Date(user.effectiveEndDate), timeZone)
      : null;

    return {
      id: user.id,
      companyName: user.companyName || null,
      companyWebsite: user.companyWebsite || null,
      countryId: user.countryId || null,
      effectiveDates: [effectiveStartDate, effectiveEndDate],
      email: user.email,
      name: user.name,
      oktaVerified: user.oktaVerified || false,
      phoneNumber: user.phoneNumber,
      postalCode: user.postalCode || null,
      preferredLanguageId: user.preferredLanguageId || null,
      roleId: user.roleId || null,
      userName: user.userName,
    };
  }
}
