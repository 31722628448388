import { Injectable, OnDestroy } from '@angular/core';
import { AnnouncementApiService } from '@api/annoucement/announcement.api';
import { Announcement, AnnouncementStatus } from '@models/announcement';
import { ApiResponse } from '@models/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { promptMessageOnError } from '@utils/rxjs/prompt-message-on-error';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class AnnouncementService implements OnDestroy {
  private _announcement = new BehaviorSubject<Announcement>(null);
  announcement$ = this._announcement.asObservable();

  private _publishedAnnouncement = new BehaviorSubject<Announcement>(null);
  publishedAnnouncement$ = this._publishedAnnouncement.asObservable();

  get announcement(): Announcement {
    return this._announcement.value;
  }

  get publishedAnnouncement(): Announcement {
    return this._publishedAnnouncement.value;
  }

  constructor(
    private api: AnnouncementApiService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    // Reserve for `untilDestroyed()` operator.
  }

  save(announcement: Announcement): Observable<Announcement> {
    const title = this.translateService.instant('ANNOUNCEMENT__FAILED_TO_SAVE');
    return this.createOrUpdateAnnouncement(announcement).pipe(
      map(({ data }) => data),
      tap((updatedAnnouncement) => {
        if (updatedAnnouncement.status === AnnouncementStatus.PUBLISHED) {
          this._publishedAnnouncement.next(updatedAnnouncement);
          return;
        }
        this._announcement.next(updatedAnnouncement);
      }),
      promptMessageOnError(this.messageService, title),
      untilDestroyed(this)
    );
  }

  get(): Observable<Announcement> {
    const title = this.translateService.instant('ANNOUNCEMENT__FAILED_TO_GET');
    return this.api.getLatest().pipe(
      map(({ data }) => data),
      filter((announcement) => !!announcement),
      tap((announcement) => {
        if (announcement.status === AnnouncementStatus.DRAFT) {
          this._announcement.next(announcement);
          if (!!announcement.previousAnnouncementId) {
            this.getById(announcement.previousAnnouncementId);
          }
          return;
        }
        this._announcement.next(null);
        this._publishedAnnouncement.next(announcement);
      }),
      promptMessageOnError(this.messageService, title),
      untilDestroyed(this)
    );
  }

  private getById(id: number): void {
    const title = this.translateService.instant('ANNOUNCEMENT__FAILED_TO_GET');
    this.api
      .getById(id)
      .pipe(
        map(({ data }) => data),
        tap((announcement) => this._publishedAnnouncement.next(announcement)),
        promptMessageOnError(this.messageService, title),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private createOrUpdateAnnouncement(
    announcement: Announcement
  ): Observable<ApiResponse<Announcement>> {
    if (announcement.id) {
      return this.api.update(announcement);
    }
    return this.api.create(announcement);
  }
}
