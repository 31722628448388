import { Injectable, OnDestroy } from '@angular/core';
import { CodeTableApiService } from '@api/code-table/code-table.api';
import { UserSettingApiService } from '@api/user-setting/user-setting.api';
import { KeyValuePair, Pagination } from '@models/api';
import { UserSetting, UserSettingKey } from '@models/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TimezoneService } from '@services/timezone/timezone.service';
import { UserService } from '@services/user/user.service';
import { promptMessageOnError } from '@utils/rxjs/prompt-message-on-error';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';

const DEFAULT_PAGINATION: Pagination = {
  current: 1,
  pageSize: 1000,
  total: null,
};

@UntilDestroy()
@Injectable()
export class TimezoneDialogService implements OnDestroy {
  private timezones = new BehaviorSubject<KeyValuePair[]>([]);
  timezones$ = this.timezones.asObservable();

  private userSetting = new BehaviorSubject<UserSetting>(null);
  userSetting$ = this.userSetting.asObservable();

  visible = false;

  private getSubscription: Subscription;
  private saveSubscription: Subscription;

  constructor(
    private api: UserSettingApiService,
    private codeTableApi: CodeTableApiService,
    private timezoneService: TimezoneService,
    private messsageService: MessageService,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    this.getTimezones();
  }

  ngOnDestroy(): void {
    // Reserve for `untilDestroyed()` operator.
  }

  open(): void {
    const userSetting = {
      settingValue: this.timezoneService.offset,
    } as UserSetting;
    this.userSetting.next(userSetting);
    this.getTimezone();
    this.visible = true;
  }

  close(): void {
    this.reset();
    this.visible = false;
  }

  save(userSetting: UserSetting): void {
    if (this.saveSubscription && !this.saveSubscription.closed) {
      return;
    }

    if (this.userSetting.value?.settingValue === userSetting.settingValue) {
      this.close();
      return;
    }

    const title = this.translateService.instant('TIMEZONE__FAILED_TO_UPDATE');

    userSetting.userId = this.userService.userDetails.id;
    userSetting.settingKey = UserSettingKey.TIMEZONE;
    userSetting.dataType = 'string';

    this.createOrUpdateTimezone(userSetting)
      .pipe(
        tap(() =>
          this.messsageService.add({
            severity: 'success',
            summary: this.translateService.instant(
              'TIMEZONE__UPDATE_SUCCESSFUL'
            ),
          })
        ),
        tap(() => {
          const selectedTimezone = this.timezones.value.find(
            (timezone) => timezone.code === userSetting.settingValue
          );
          this.timezoneService.setTimezone({
            area: selectedTimezone.name,
            offset: selectedTimezone.code,
          });
        }),
        tap(() => this.close()),
        delay(800),
        tap(() => window.location.reload()),
        promptMessageOnError(this.messsageService, title),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getTimezone(): void {
    const userId = this.userService.userDetails.id;
    const title = this.translateService.instant(
      'TIMEZONE__FAILED_TO_GET_TIMEZONE'
    );
    this.getSubscription = this.api
      .get(userId, UserSettingKey.TIMEZONE)
      .pipe(
        map(({ data }) => data),
        filter((userSetting) => !!userSetting),
        tap((userSetting) => this.userSetting.next(userSetting)),
        promptMessageOnError(this.messsageService, title),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getTimezones(): void {
    const title = this.translateService.instant(
      'TIMEZONE__FAILED_TO_GET_TIMEZONES'
    );
    this.codeTableApi
      .getTimezones(null, DEFAULT_PAGINATION)
      .pipe(
        map(({ data }) => data.data),
        map((timezones) =>
          timezones?.map((timezone) => ({
            ...timezone,
            name: `${timezone.name}     ${format(
              utcToZonedTime(new Date(), timezone.code),
              'dd MMM yyyy hh:mm a'
            )}`,
          }))
        ),
        tap((timezones) => this.timezones.next(timezones)),
        promptMessageOnError(this.messsageService, title),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private createOrUpdateTimezone(userSetting: UserSetting): Observable<any> {
    if (userSetting.id) {
      return this.api.update(userSetting);
    }
    return this.api.create(userSetting);
  }

  private reset(): void {
    if (this.getSubscription && !this.getSubscription.closed) {
      this.getSubscription.unsubscribe();
    }

    if (this.saveSubscription && !this.saveSubscription.closed) {
      this.saveSubscription.unsubscribe();
    }

    this.getSubscription = null;
    this.saveSubscription = null;
    this.userSetting.next(null);
  }
}
