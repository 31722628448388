import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountApiService } from '@api/account/account.api';
import { ApplicationUserApiService } from '@api/application-user/application-user.api';
import { MessageService } from 'primeng/api';
import { catchError, tap } from 'rxjs/operators';

const ACCESS_TOKEN_KEY = 'access-token';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _storage = localStorage;
  private _accessToken = this.getAccessToken();

  get isAuthenticated(): boolean {
    return !!this._accessToken;
  }

  get accessToken(): string {
    return this._accessToken;
  }

  constructor(
    private api: AccountApiService,
    private applicationUserApi: ApplicationUserApiService,
    private messageService: MessageService,
    private router: Router
  ) {
    if (!!this._accessToken) {
      return;
    }
    this._accessToken = this.getAccessToken();
  }

  login(
    username: string,
    password: string,
    isRememberMe: boolean = false
  ): void {
    this.api
      .login(username, password)
      .pipe(
        catchError((error) => {
          this.promptErrorMessage('Login Failed', error?.message);
          throw error;
        }),
        tap(({ access_token }) => {
          this._accessToken = access_token;
          this.setAccessToken(access_token);
        }),
        tap(() => this.router.navigate(['/']))
      )
      // tslint:disable-next-line: deprecation
      .subscribe();
  }

  logout(): void {
    this.removeAccessToken();
    this.applicationUserApi
      .logout()
      .pipe(
        tap((logoutUrl) => window.location.replace(logoutUrl)),
        catchError((error) => {
          const url = `/error?errorMessage=Logout failed. Please contact administrator.`;
          this.router.navigateByUrl(url);
          throw error;
        })
      )
      .subscribe();
  }

  private promptErrorMessage(title: string, message: string): void {
    this.messageService.add({
      severity: 'error',
      summary: title,
      detail: message,
    });
  }

  private getAccessToken(): string {
    return this._storage.getItem(ACCESS_TOKEN_KEY);
  }

  setAccessToken(accessToken: string): void {
    this._accessToken = accessToken;
    this._storage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }

  removeAccessToken(): void {
    this._accessToken = null;
    this._storage.removeItem(ACCESS_TOKEN_KEY);
  }
}
