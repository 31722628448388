import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { DropdownMenuItemComponent } from './components/dropdown-menu-item/dropdown-menu-item.component';
import { MenuComponent } from './menu.component';

@NgModule({
  declarations: [DropdownMenuItemComponent, MenuComponent],
  imports: [CommonModule, TieredMenuModule],
  exports: [MenuComponent],
})
export class MenuModule {}
