import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccessRightDirective } from './access-right.directive';

@NgModule({
  declarations: [AccessRightDirective],
  imports: [CommonModule],
  exports: [AccessRightDirective],
})
export class AccessRightDirectiveModule {}
