import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ViewAnnouncementDialogService } from './view-announcement-dialog.service';

@UntilDestroy()
@Component({
  selector: 'app-view-announcement-dialog',
  templateUrl: './view-announcement-dialog.component.html',
  styleUrls: ['./view-announcement-dialog.component.scss'],
})
export class ViewAnnouncementDialogComponent {
  constructor(public presenter: ViewAnnouncementDialogService) {}

  onClose(): void {
    this.presenter.close();
  }
}
