import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserSettingKey } from '@models/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { LanguageDialogService } from './language-dialog.service';

@UntilDestroy()
@Component({
  selector: 'app-language-dialog',
  templateUrl: './language-dialog.component.html',
  styleUrls: ['./language-dialog.component.scss'],
})
export class LanguageDialogComponent implements OnInit, OnDestroy {
  formGroup = new FormGroup({
    id: new FormControl(),
    userId: new FormControl(),
    settingKey: new FormControl(UserSettingKey.LANGUAGE),
    settingValue: new FormControl(null, Validators.required),
    dataType: new FormControl(),
    concurrencyToken: new FormControl(),
  });

  constructor(
    public presenter: LanguageDialogService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.patchFormGroupOnLanguageChange();
  }

  ngOnDestroy(): void {
    // Reserve for `untilDestroyed()` operator.
  }

  onSave(): void {
    if (this.formGroup.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('MESSAGE__INVALID_FORM'),
      });
      return;
    }
    const language = this.formGroup.value;
    this.presenter.save(language);
  }

  onCancel(): void {
    this.presenter.close();
  }

  private patchFormGroupOnLanguageChange(): void {
    this.presenter.language$
      .pipe(
        tap(() => this.formGroup.reset()),
        tap((language) => this.formGroup.patchValue(language)),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
