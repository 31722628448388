import { Injectable } from '@angular/core';

const STORAGE_KEY = 'timezone';
const DEFAULT_TIMEZONE = { area: 'Singapore', offset: '+0800' };

export interface Timezone {
  area: string;
  offset: string;
}

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  private _timezone: Timezone = DEFAULT_TIMEZONE;

  get timezone(): Timezone {
    return this._timezone;
  }

  get offset(): string {
    return this._timezone?.offset;
  }

  constructor() {
    const timezone = this.getTimezoneFromSessionStorage();
    if (!timezone) {
      this.setTimezone();
      return;
    }
    this._timezone = timezone;
  }

  setTimezone(timezone: Timezone = DEFAULT_TIMEZONE): void {
    this._timezone = timezone;
    const timezoneString = JSON.stringify(timezone);
    sessionStorage.setItem(STORAGE_KEY, timezoneString);
  }

  private getTimezoneFromSessionStorage(): Timezone {
    const timezoneString = sessionStorage.getItem(STORAGE_KEY);
    const timezone = JSON.parse(timezoneString);

    if (!timezoneString) {
      return null;
    }

    if (!timezone || !timezone.area || !timezone.offset) {
      return null;
    }

    return { area: timezone.area, offset: timezone.offset };
  }
}
