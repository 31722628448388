import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserSettingKey } from '@models/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { TimezoneDialogService } from './timezone-dialog.service';

@UntilDestroy()
@Component({
  selector: 'app-timezone-dialog',
  templateUrl: './timezone-dialog.component.html',
  styleUrls: ['./timezone-dialog.component.scss'],
})
export class TimezoneDialogComponent implements OnInit, OnDestroy {
  formGroup = new FormGroup({
    id: new FormControl(),
    userId: new FormControl(),
    settingKey: new FormControl(UserSettingKey.TIMEZONE),
    settingValue: new FormControl(null, Validators.required),
    dataType: new FormControl(),
    concurrencyToken: new FormControl(),
  });

  constructor(
    public presenter: TimezoneDialogService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.patchFormGroupOnUserSettingChange();
  }

  ngOnDestroy(): void {
    // Reserve for `untilDestroyed()` operator.
  }

  onSave(): void {
    if (this.formGroup.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('MESSAGE__INVALID_FORM'),
      });
      return;
    }
    const timezone = this.formGroup.value;
    this.presenter.save(timezone);
  }

  onCancel(): void {
    this.presenter.close();
  }

  private patchFormGroupOnUserSettingChange(): void {
    this.presenter.userSetting$
      .pipe(
        tap(() => this.formGroup.reset()),
        tap((userSetting) => this.formGroup.patchValue(userSetting)),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
