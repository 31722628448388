import { Component, HostBinding } from '@angular/core';
import { LoadingUiBlockerService } from './services/loading-ui-blocker.service';

@Component({
  selector: 'app-loading-ui-blocker',
  templateUrl: './loading-ui-blocker.component.html',
  styleUrls: ['./loading-ui-blocker.component.scss'],
})
export class LoadingUiBlockerComponent {
  @HostBinding('class.isHidden')
  get isHidden(): boolean {
    return !this.loadingUIBlockerService.isLoading;
  }

  constructor(private loadingUIBlockerService: LoadingUiBlockerService) {}
}
