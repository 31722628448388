export class AccessRightUtility {
  static isValidPattern(value: string): boolean {
    const regex =
      /^([a-zA-Z0-9]+\*{0,1}\.[a-zA-Z0-9]+\*{0,1}\.[a-zA-Z0-9]+\*{0,1})$/g;
    return regex.test(value);
  }

  static transformPatternToRegExp(value: string): RegExp {
    value = value.replace(/\./g, '\\.');
    value = value.replace(/\:/g, '\\:');
    value = value.replace(/\*/g, '[a-zA-Z0-9:/.]+');
    return new RegExp(`^${value}$`);
  }
}
