import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Announcement } from '@models/announcement';
import { ApiResponse } from '@models/api';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementApiService {
  private apiUrl: string;

  constructor(
    @Inject(ENV_CONFIG) envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {
    this.apiUrl = `${envConfig.environment.baseUrl}/api/announcement`;
  }

  getLatest(): Observable<ApiResponse<Announcement>> {
    const url = `${this.apiUrl}/latest`;
    return this.http.get<ApiResponse<Announcement>>(url);
  }

  getById(id: number): Observable<ApiResponse<Announcement>> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<ApiResponse<Announcement>>(url);
  }

  create(annoucement: Announcement): Observable<ApiResponse<Announcement>> {
    return this.http.post<ApiResponse<Announcement>>(this.apiUrl, annoucement);
  }

  update(annoucement: Announcement): Observable<ApiResponse<Announcement>> {
    const url = `${this.apiUrl}/${annoucement.id}`;
    return this.http.put<ApiResponse<Announcement>>(url, annoucement);
  }

  delete(id: number): Observable<ApiResponse<boolean>> {
    const url = `${this.apiUrl}?id=${id}`;
    return this.http.delete<ApiResponse<boolean>>(url);
  }
}
