import { Component } from '@angular/core';
import { HelpDialogService } from './help-dialog.service';

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss'],
})
export class HelpDialogComponent {
  currentTab = 1;

  constructor(public presenter: HelpDialogService) {}

  onCancel(): void {
    this.presenter.close();
  }

  onChangeTab(index: number): void {
    this.currentTab = index;
  }
}
