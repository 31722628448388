import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown-menu-item',
  templateUrl: './dropdown-menu-item.component.html',
  styleUrls: ['./dropdown-menu-item.component.scss'],
})
export class DropdownMenuItemComponent implements AfterViewInit {
  @Input()
  label: string;

  @Input()
  visible: boolean;

  @Input()
  menuItems: MenuItem[];

  @ViewChild('button')
  private buttonElement: ElementRef;

  private currentRoute: string;

  constructor(private router: Router) {
    this.listenToRouteChanges();
  }

  ngAfterViewInit(): void {
    this.currentRoute = this.router.routerState.snapshot.url;

    const isActiveRoute = this.findMatchRoute(this.menuItems);
    this.updateButton(isActiveRoute);
  }

  private listenToRouteChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => (this.currentRoute = this.router.routerState.snapshot.url)),
        map(() => this.findMatchRoute(this.menuItems)),
        tap((isActive) => this.updateButton(isActive))
      )
      .subscribe();
  }

  private findMatchRoute(menuItems: MenuItem[]): boolean {
    return !!menuItems?.find(
      (item) => this.findMatchNestedRoute(item) === true
    );
  }

  private findMatchNestedRoute(menuItem: MenuItem): boolean {
    if (!!menuItem.items) {
      return !!menuItem.items?.find((item) => this.findMatchNestedRoute(item));
    }
    return this.currentRoute.startsWith(menuItem.routerLink);
  }

  private updateButton(isActive: boolean): void {
    if (
      isActive &&
      this.buttonElement.nativeElement.className.includes('menuItem-active')
    ) {
      return;
    }

    if (isActive) {
      this.buttonElement.nativeElement.className += ' menuItem-active';
      return;
    }

    this.buttonElement.nativeElement.className =
      this.buttonElement.nativeElement.className
        .replaceAll('menuItem-active', '')
        .trim();
  }
}
