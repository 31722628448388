import { Component, ViewChild } from '@angular/core';
import { JabilUserFormComponent } from '@components/jabil-user-form/jabil-user-form.component';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { MyProfileDialogService } from './my-profile-dialog.service';

@Component({
  selector: 'app-my-profile-dialog',
  templateUrl: './my-profile-dialog.component.html',
  styleUrls: ['./my-profile-dialog.component.scss'],
})
export class MyProfileDialogComponent {
  @ViewChild(JabilUserFormComponent)
  form!: JabilUserFormComponent;

  constructor(
    public presenter: MyProfileDialogService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  onSave(): void {
    if (this.form.formGroup.invalid) {
      this.form.formGroup.markAllAsTouched();
      this.messageService.add({
        severity: 'error',
        summary: this.translateService.instant('MESSAGE__INVALID_FORM'),
      });
      return;
    }
    const user = this.form.user;
    this.presenter.save(user);
  }

  onCancel(): void {
    this.presenter.close();
  }
}
