export interface AccessRight {
  id: number;
  permission: string;
  accessRight: string;
  module: string;
  subModule: string;
  function: string;
}

export enum AccessRightModulePermission {
  HIDDEN = 'Hidden',
  VISIBLE = 'Visible',
}

export enum AccessRightActionPermission {
  ENABLE = 'Enable',
  DISABLE = 'Disable',
}
