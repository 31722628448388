export interface Announcement {
  id: number;
  title: string;
  description: string;
  status: string;
  isActive: boolean;
  previousAnnouncementId?: number;
  createDate?: Date;
  updateDate?: Date;
  concurrencyToken: number;
}

export enum AnnouncementStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}
