import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse } from '@models/api';
import { UserSetting } from '@models/user';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSettingApiService {
  private apiUrl = `${this.envConfig.environment.baseUrl}/api/UserSetting`;

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {}

  get(userId: number, key: string): Observable<ApiResponse<UserSetting>> {
    const url = `${this.apiUrl}?userId=${userId}&key=${key}`;
    return this.http.get<ApiResponse<UserSetting>>(url);
  }

  getAll(userId: number): Observable<ApiResponse<UserSetting[]>> {
    const url = `${this.apiUrl}/${userId}`;
    return this.http.get<ApiResponse<UserSetting[]>>(url);
  }

  create(userSetting: UserSetting): Observable<ApiResponse<UserSetting>> {
    return this.http.post<ApiResponse<UserSetting>>(this.apiUrl, userSetting);
  }

  update(userSetting: UserSetting): Observable<null> {
    return this.http.put<null>(this.apiUrl, userSetting);
  }
}
