import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiResponse } from '@models/api';
import { Notification } from '@models/notification';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  private apiUrl = `${this.envConfig.environment.baseUrl}/api/notification`;

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {}

  get(): Observable<ApiResponse<Notification[]>> {
    return this.http.get<ApiResponse<Notification[]>>(this.apiUrl);
  }
}
