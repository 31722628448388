<div class="mainLayout">
  <ui-ng-layout #layout [topTemplate]="topTemplate">
    <router-outlet></router-outlet>
  </ui-ng-layout>

  <ng-template #topTemplate>
    <div class="mainLayout__header">
      <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
    </div>
  </ng-template>
</div>

<app-loading-ui-blocker></app-loading-ui-blocker>
