import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingUiBlockerService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoading.asObservable();

  get isLoading(): boolean {
    return this._isLoading.value;
  }

  constructor() {}

  show(): void {
    this._isLoading.next(true);
  }

  hide(): void {
    this._isLoading.next(false);
  }

  setIsLoading(isLoading: boolean): void {
    this._isLoading.next(isLoading);
  }
}
