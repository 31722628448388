import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { UserService } from '@services/user/user.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-visitor-layout',
  templateUrl: './visitor-layout.component.html',
  styleUrls: ['./visitor-layout.component.scss'],
})
export class VisitorLayoutComponent {
  mainMenus: MenuItem[] = [
    {
      label: this.translateService.instant('MENU__LEARN'),
      routerLink: '/view/learn',
    },
  ];
  settingMenus: MenuItem[] = [
    {
      label: this.translateService.instant('LOGOUT'),
      command: this.onLogout.bind(this),
    },
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    public userService: UserService
  ) {}

  onLogout(): void {
    this.authenticationService.logout();
  }
}
