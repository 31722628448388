import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AccountApiService } from '@api/account/account.api';
import { Announcement } from '@models/announcement';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { AuthorizationService } from '@services/authorization/authorization.service';
import { IdleService } from '@services/idle/idle.service';
import { UserService } from '@services/user/user.service';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { promptMessageOnError } from '@utils/rxjs/prompt-message-on-error';
import { version } from 'package.json';
import { MenuItem, MessageService } from 'primeng/api';
import { map, tap } from 'rxjs/operators';
import { AnnouncementDialogService } from './components/announcement-dialog/announcement-dialog.service';
import { HelpDialogService } from './components/help-dialog/help-dialog.service';
import { LanguageDialogService } from './components/language-dialog/language-dialog.service';
import { LogoutCountdownDialogService } from './components/logout-countdown-dialog/logout-countdown-dialog.service';
import { MyProfileDialogService } from './components/my-profile-dialog/my-profile-dialog.service';
import { NotificationOverlayPanelComponent } from './components/notification-overlay-panel/notification-overlay-panel.component';
import { TimezoneDialogService } from './components/timezone-dialog/timezone-dialog.service';
import { ViewAnnouncementDialogService } from './components/view-announcement-dialog/view-announcement-dialog.service';
import { AnnouncementService } from './services/announcement.service';
import { NotificationService } from './services/notification.service';

@UntilDestroy()
@Component({
  selector: 'app-jabil-user-layout',
  templateUrl: './jabil-user-layout.component.html',
  styleUrls: ['./jabil-user-layout.component.scss'],
  providers: [
    AnnouncementService,
    AnnouncementDialogService,
    HelpDialogService,
    LanguageDialogService,
    LogoutCountdownDialogService,
    MyProfileDialogService,
    NotificationService,
    TimezoneDialogService,
    ViewAnnouncementDialogService,
  ],
})
export class JabilUserLayoutComponent implements OnDestroy, OnInit {
  @ViewChild(NotificationOverlayPanelComponent)
  notificationPanel!: NotificationOverlayPanelComponent;

  mainMenus: MenuItem[] = [
    {
      label: this.translateService.instant('MENU__CONTENT'),
      items: [
        {
          label: this.translateService.instant('MENU__CONTENT'),
          routerLink: '/content/manage',
          visible: this.authorizationService.validate(
            'CMSWebApp:Content(|/SearchMagnoliaAsset)'
          ),
        },
        {
          label: this.translateService.instant('MENU__ARCHIVED_CONTENT'),
          routerLink: '/content/archived',
          visible: this.authorizationService.validate('CMSWebApp:Archive'),
        },
      ],
      visible: this.authorizationService.validate(
        'CMSWebApp:(Content(|/SearchMagnoliaAsset)|Archive)'
      ),
    },
    {
      label: this.translateService.instant('MENU__SCHEDULER'),
      routerLink: '/schedule',
      visible: this.authorizationService.validate('CMSWebApp:Scheduler'),
    },
    {
      label: this.translateService.instant('MENU__USER_MANAGEMENT'),
      items: [
        {
          label: this.translateService.instant('MENU__JABIL_USER'),
          routerLink: '/user-management/jabil-user',
          visible: this.authorizationService.validate(
            'CMSWebApp:UserManagement/JabilUsers'
          ),
        },
        {
          label: this.translateService.instant('MENU__VISITOR'),
          routerLink: '/user-management/visitor',
          visible: this.authorizationService.validate(
            'CMSWebApp:UserManagement/(Visitor|VisitorToBeArchived)'
          ),
        },
        {
          label: this.translateService.instant('MENU__ROLE_AND_ACCESS_RIGHT'),
          routerLink: '/user-management/role-and-access-right',
          visible: this.authorizationService.validate(
            'CMSWebApp:UserManagement/Role&AccessRight'
          ),
        },
      ],
      visible: this.authorizationService.validate('CMSWebApp:UserManagement'),
    },
    {
      label: this.translateService.instant('MENU__LMS'),
      routerLink: '/manage/learn',
      visible: this.authorizationService.validate('CMSWebApp:LMS'),
    },
  ];
  settingMenus: MenuItem[] = [
    {
      label: this.translateService.instant('MENU__MY_PROFILE'),
      command: this.onMyProfile.bind(this),
    },
    {
      label: this.translateService.instant('MENU__ANNOUNCEMENT'),
      command: this.onAnnouncement.bind(this),
    },
    {
      label: this.translateService.instant('MENU__LANGUAGE'),
      command: this.onLanguage.bind(this),
    },
    {
      label: this.translateService.instant('MENU__TIMEZONE'),
      command: this.onTimezone.bind(this),
    },
    {
      label: this.translateService.instant('MENU__TOOLS'),
      command: this.onTools.bind(this),
    },
    {
      label: this.translateService.instant('LOGOUT'),
      command: this.onLogout.bind(this),
    },
  ];
  version = version;
  displayEnvironment = this.environmentConfig.environment.displayEnvironment;

  constructor(
    @Inject(ENV_CONFIG) private environmentConfig: EnvironmentConfig,
    private accountApi: AccountApiService,
    private announcementDialogService: AnnouncementDialogService,
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private helpDialogService: HelpDialogService,
    private idleService: IdleService,
    private languageDialogService: LanguageDialogService,
    private logoutCountdownDialogService: LogoutCountdownDialogService,
    private messageService: MessageService,
    private myProfileDialogService: MyProfileDialogService,
    private timezoneDialogService: TimezoneDialogService,
    private translateService: TranslateService,
    private viewAnnouncementDialogService: ViewAnnouncementDialogService,
    public announcementService: AnnouncementService,
    public notificationService: NotificationService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.idleService.start();
    this.idleService.idleEvent
      .pipe(
        tap(() => this.logoutCountdownDialogService.open()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    // Reserve for `untilDestroyed()` operator.
    this.idleService.stop();
  }

  openNotificationPanel(event: MouseEvent): void {
    if (!this.notificationPanel.isVisible) {
      this.announcementService.get().subscribe();
      this.notificationService.get();
    }
    this.notificationPanel.toggle(event);
  }

  onAnnouncement(): void {
    this.announcementDialogService.open();
  }

  onViewAnnouncement(announcement: Announcement): void {
    if (!announcement?.description || announcement.description.length < 200) {
      return;
    }
    this.viewAnnouncementDialogService.open(announcement);
  }

  onHelp(): void {
    this.helpDialogService.open();
  }

  onLanguage(): void {
    this.languageDialogService.open();
  }

  onTimezone(): void {
    this.timezoneDialogService.open();
  }

  onTools(): void {
    window.open('/tools/code-generator', '_blank', 'width=1000,height=600');
  }

  onContextSwitch(): void {
    const token = this.authenticationService.accessToken;
    const title = 'Failed to switch to cloud expo';
    this.accountApi
      .contextSwitch(token)
      .pipe(
        map(
          ({ access_token }) =>
            `${this.environmentConfig.environment.cloudExpoUrl}?cl_token=${access_token}`
        ),
        tap((url) => window.open(url, 'blank')),
        promptMessageOnError(this.messageService, title),
        untilDestroyed(this)
      )
      .subscribe();
  }

  onLogout(): void {
    this.authenticationService.logout();
  }

  onMyProfile(): void {
    this.myProfileDialogService.open();
  }
}
