import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  @Input()
  menuTemplate: TemplateRef<any>;

  constructor() {}
}
