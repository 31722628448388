import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApplicationUserApiService } from '@api/application-user/application-user.api';
import { UserType } from '@models/user';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { UserService } from '@services/user/user.service';
import { IsAuthenticateUserGuard } from '../is-authenticate-user/is-authenticate-user.guard';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedJabilUserGuard
  extends IsAuthenticateUserGuard
  implements CanActivate
{
  constructor(
    protected authenticationService: AuthenticationService,
    protected applicationUserApi: ApplicationUserApiService,
    protected router: Router,
    private userService: UserService
  ) {
    super(authenticationService, applicationUserApi, router);
  }

  async canActivate(): Promise<boolean> {
    const isAuthenticated = await super.canActivate();

    if (!isAuthenticated) {
      return false;
    }

    try {
      const { type } = await this.userService.getCurrentUserDetails();

      if (type === UserType.VISITOR || type === UserType.INTERNAL_VISITOR) {
        this.router.navigateByUrl('/view/learn');
      }

      return type === UserType.JABIL_USER;
    } catch (error: any) {
      this.authenticationService.logout();
      throw error;
    }
  }
}
