export function toTitleCase(value: string): string {
  if (!value) {
    return value;
  }

  return value
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
