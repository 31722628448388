import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JabilUserLayoutComponent } from '@components/layouts/jabil-user-layout/jabil-user-layout.component';
import { JabilUserLayoutModule } from '@components/layouts/jabil-user-layout/jabil-user-layout.module';
import { MainLayoutComponent } from '@components/layouts/main-layout/main-layout.component';
import { MainLayoutModule } from '@components/layouts/main-layout/main-layout.module';
import { VisitorLayoutComponent } from '@components/layouts/visitor-layout/visitor-layout.component';
import { VisitorLayoutModule } from '@components/layouts/visitor-layout/visitor-layout.module';
import { AccessRightGuard } from '@utils/guards/access-right/access-right.guard';
import { GetAccessRightGuard } from '@utils/guards/get-access-right/get-access-right.guard';
import { IsAuthenticateUserGuard } from '@utils/guards/is-authenticate-user/is-authenticate-user.guard';
import { IsAuthenticatedJabilUserGuard } from '@utils/guards/is-authenticated-jabil-user/is-authenticated-jabil-user.guard';
import { IsGuestUserGuard } from '@utils/guards/is-guest-user/is-guest-user.guard';
import { UserDetailsResolver } from './resolvers/user-details/user-details.resolver';

const routes: Routes = [
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./modules/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
    canActivate: [IsGuestUserGuard],
  },
  {
    path: 'view/learn',
    component: VisitorLayoutComponent,
    loadChildren: () =>
      import('./modules/learn/modules/view/view.module').then(
        (m) => m.ViewModule
      ),
    canActivate: [IsAuthenticateUserGuard],
    resolve: { userDetails: UserDetailsResolver },
  },
  {
    path: 'view/**',
    redirectTo: 'view/learn',
  },
  {
    path: 'tools',
    loadChildren: () =>
      import('./modules/tools/tools.module').then((m) => m.ToolsModule),
  },
  {
    path: '',
    component: JabilUserLayoutComponent,
    children: [
      {
        path: 'content',
        loadChildren: () =>
          import('./modules/content/content.module').then(
            (m) => m.ContentModule
          ),
        canActivate: [AccessRightGuard],
        data: {
          accessRight: 'CMSWebApp:(Content(|/SearchMagnoliaAsset)|Archive)',
          redirectTo: '/schedule',
        },
      },
      {
        path: 'schedule',
        loadChildren: () =>
          import('./modules/schedule/schedule.module').then(
            (m) => m.ScheduleModule
          ),
        canActivate: [AccessRightGuard],
        data: {
          accessRight: 'CMSWebApp:Scheduler',
          redirectTo: '/user-management',
        },
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./modules/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
        canActivate: [AccessRightGuard],
        data: {
          accessRight: 'CMSWebApp:UserManagement',
          redirectTo: '/lms',
        },
      },
      {
        path: 'manage/learn',
        loadChildren: () =>
          import('./modules/learn/modules/manage/manage.module').then(
            (m) => m.ManageModule
          ),
        canActivate: [AccessRightGuard],
        data: { accessRight: 'CMSWebApp:LMS' },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'content',
      },
      {
        path: '**',
        redirectTo: 'content',
      },
    ],
    canActivate: [IsAuthenticatedJabilUserGuard, GetAccessRightGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    MainLayoutModule,
    JabilUserLayoutModule,
    VisitorLayoutModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
