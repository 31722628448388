<span *ngFor="let menuItem of menuItems">
  <ng-container
    *ngTemplateOutlet="
      menuItem.items?.length > 0 ? withChildItem : withoutChildItem;
      context: menuItem
    "
  ></ng-container>
</span>

<ng-template
  #withChildItem
  let-label="label"
  let-visible="visible"
  let-items="items"
>
  <app-dropdown-menu-item
    [label]="label"
    [visible]="visible"
    [menuItems]="items"
  ></app-dropdown-menu-item>
</ng-template>

<ng-template
  #withoutChildItem
  let-routerLink="routerLink"
  let-label="label"
  let-visible="visible"
>
  <button
    class="menuItem"
    *ngIf="visible !== false"
    [routerLink]="routerLink"
    routerLinkActive="menuItem-active"
  >
    {{ label }}
  </button>
</ng-template>
