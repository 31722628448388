import { Injectable } from '@angular/core';
import {
  AccessRight,
  AccessRightActionPermission,
  AccessRightModulePermission,
} from '@models/user';
import { AccessRightUtility } from '@utils/access-right/access-right';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private _accessRights = new BehaviorSubject<AccessRight[]>([]);
  accessRights$ = this._accessRights.asObservable();

  get accessRights(): AccessRight[] {
    return this._accessRights.value;
  }

  constructor() {}

  validate(accessRight: string): boolean {
    if (!accessRight) {
      throw new Error('No access right provided.');
    }

    const token = AccessRightUtility.transformPatternToRegExp(accessRight);
    return this.isValid(this.accessRights, token);
  }

  updateAccessRights(accessRights: AccessRight[]): void {
    this._accessRights.next(accessRights);
  }

  private isValid(source: AccessRight[], token: RegExp): boolean {
    if (!source) {
      return false;
    }

    return (
      source
        .filter(
          (accessRight) =>
            accessRight.permission !== AccessRightModulePermission.HIDDEN &&
            accessRight.permission !== AccessRightActionPermission.DISABLE
        )
        .findIndex(({ accessRight }) => token.test(accessRight)) > -1
    );
  }
}
