import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthorizationService } from '@services/authorization/authorization.service';

@Directive({
  selector: '[appAccessRight]',
})
export class AccessRightDirective implements OnInit {
  @Input('appAccessRight')
  accessRight: string;

  constructor(
    private authorizationService: AuthorizationService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    if (!this.accessRight) {
      this.elementRef.nativeElement.style.display = 'none';
      return;
    }

    const isValid = this.authorizationService.validate(this.accessRight);

    if (isValid) {
      return;
    }

    this.elementRef.nativeElement.style.display = 'none';
  }
}
