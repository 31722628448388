import { Injectable, OnDestroy } from '@angular/core';
import { ApplicationUserApiService } from '@api/application-user/application-user.api';
import { CodeTableApiService } from '@api/code-table/code-table.api';
import { KeyValuePair, Pagination } from '@models/api';
import { User } from '@models/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@services/user/user.service';
import { promptMessageOnError } from '@utils/rxjs/prompt-message-on-error';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const DEFAULT_PAGINATION: Pagination = {
  current: 1,
  pageSize: 1000,
  total: null,
};

@UntilDestroy()
@Injectable()
export class MyProfileDialogService implements OnDestroy {
  private countries = new BehaviorSubject<KeyValuePair[]>([]);
  countries$ = this.countries.asObservable();

  private languages = new BehaviorSubject<KeyValuePair[]>([]);
  languages$ = this.languages.asObservable();

  private roles = new BehaviorSubject<KeyValuePair[]>([]);
  roles$ = this.roles.asObservable();

  private user = new BehaviorSubject<User>(null);
  user$ = this.user.asObservable();

  visible = false;

  private getUserSubscription: Subscription;
  private updateUserSubscription: Subscription;

  constructor(
    private applicationUserApi: ApplicationUserApiService,
    private codeTableApi: CodeTableApiService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private userService: UserService
  ) {
    const userId = this.userService.userDetails.id;
    if (!userId) {
      return;
    }
    this.getUser(userId);
    this.getCountries();
    this.getLanguages();
  }

  ngOnDestroy(): void {
    // Reserve for `untilDestroyed()` operator.
  }

  open(): void {
    const userId = this.userService.userDetails.id;
    if (userId) {
      this.getUser(userId);
    }
    this.visible = true;
  }

  close(): void {
    this.visible = false;

    if (this.getUserSubscription && !this.getUserSubscription.closed) {
      this.getUserSubscription.unsubscribe();
    }

    if (this.updateUserSubscription && !this.updateUserSubscription.closed) {
      this.updateUserSubscription.unsubscribe();
    }
  }

  save(user: User): void {
    if (this.updateUserSubscription && !this.updateUserSubscription.closed) {
      this.promptUpdateInProgressMessage();
      return;
    }
    this.updateUserSubscription = this.applicationUserApi
      .update(user)
      .pipe(
        tap(() => this.promptUpdateSuccessMessage()),
        tap(() => this.close()),
        promptMessageOnError(
          this.messageService,
          this.translateService.instant('JABIL_USER__UPDATE_USER_FAILED')
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getUser(id: number): void {
    if (this.getUserSubscription && !this.getUserSubscription.closed) {
      this.getUserSubscription.unsubscribe();
    }
    const errorTitle = this.translateService.instant(
      'JABIL_USER__GET_USER_FAILED'
    );
    this.getUserSubscription = this.applicationUserApi
      .getOne(id)
      .pipe(
        map(({ data }) => data),
        tap((user) => this.user.next(user)),
        promptMessageOnError(this.messageService, errorTitle),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getCountries(): void {
    const errorTitle = this.translateService.instant(
      'JABIL_USER__GET_COUNTRIED_FAILED'
    );
    this.codeTableApi
      .getCountries(null, DEFAULT_PAGINATION)
      .pipe(
        map(({ data }) => data.data),
        tap((countries) => this.countries.next(countries)),
        promptMessageOnError(this.messageService, errorTitle),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getLanguages(): void {
    const errorTitle = this.translateService.instant(
      'JABIL_USER__GET_LANGUAGES_FAILED'
    );
    this.codeTableApi
      .getLanguages(null, DEFAULT_PAGINATION)
      .pipe(
        map(({ data }) => data.data),
        tap((languages) => this.languages.next(languages)),
        promptMessageOnError(this.messageService, errorTitle),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private promptUpdateInProgressMessage(): void {
    this.messageService.add({
      severity: 'warn',
      summary: this.translateService.instant(
        'JABIL_USER__UPDATE_USER_IN_PROGRESS'
      ),
    });
  }

  private promptUpdateSuccessMessage(): void {
    this.messageService.add({
      severity: 'success',
      summary: this.translateService.instant('JABIL_USER__UPDATE_USER_SUCCESS'),
    });
  }
}
