import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

type CatchErrorResponse<T> = (source: Observable<T>) => Observable<T>;

export function promptMessageOnError<T>(
  messageService: MessageService,
  title?: string,
  message?: string
): CatchErrorResponse<T> {
  return (source: Observable<T>) =>
    source.pipe(
      catchError((error) => {
        messageService.add({
          severity: 'error',
          summary: title,
          detail:
            error?.error?.Message ||
            error?.Message ||
            error?.message ||
            message,
        });
        throw error;
      })
    );
}
