import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KeyValuePair, Pagination, PaginationResult } from '@models/api';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { HttpParamsGenerator } from '@utils/http/http-params-generator';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CodeTableApiService {
  private apiUrl = `${this.envConfig.environment.baseUrl}/api/code-table`;

  constructor(
    @Inject(ENV_CONFIG) private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {}

  getCountries(
    code: string,
    pagination: Pagination
  ): Observable<PaginationResult<KeyValuePair[]>> {
    const params = new HttpParamsGenerator({ code, ...pagination }).generate();
    return this.http.get<PaginationResult<KeyValuePair[]>>(
      `${this.apiUrl}/ctcountry`,
      { params }
    );
  }

  getLanguages(
    code: string,
    pagination: Pagination
  ): Observable<PaginationResult<KeyValuePair[]>> {
    const params = new HttpParamsGenerator({ code, ...pagination }).generate();
    return this.http.get<PaginationResult<KeyValuePair[]>>(
      `${this.apiUrl}/ctlanguage`,
      { params }
    );
  }

  getTimezones(
    code: string,
    pagination: Pagination
  ): Observable<PaginationResult<KeyValuePair[]>> {
    const params = new HttpParamsGenerator({ code, ...pagination }).generate();
    return this.http.get<PaginationResult<KeyValuePair[]>>(
      `${this.apiUrl}/cttimezone`,
      { params }
    );
  }

  getTags(
    code: string,
    pagination: Pagination
  ): Observable<PaginationResult<KeyValuePair[]>> {
    const params = new HttpParamsGenerator({ code, ...pagination }).generate();
    return this.http.get<PaginationResult<KeyValuePair[]>>(
      `${this.apiUrl}/cttag`,
      { params }
    );
  }

  getProjects(
    code: string,
    pagination: Pagination
  ): Observable<PaginationResult<KeyValuePair[]>> {
    const params = new HttpParamsGenerator({ code, ...pagination }).generate();
    return this.http.get<PaginationResult<KeyValuePair[]>>(
      `${this.apiUrl}/project`,
      { params }
    );
  }

  getHosts(
    code: string,
    pagination: Pagination
  ): Observable<PaginationResult<KeyValuePair[]>> {
    const params = new HttpParamsGenerator({ code, ...pagination }).generate();
    return this.http.get<PaginationResult<KeyValuePair[]>>(
      `${this.apiUrl}/host`,
      { params }
    );
  }

  getRoles(
    code: string,
    pagination: Pagination
  ): Observable<PaginationResult<KeyValuePair[]>> {
    const params = new HttpParamsGenerator({ code, ...pagination }).generate();
    return this.http.get<PaginationResult<KeyValuePair[]>>(
      `${this.apiUrl}/role`,
      { params }
    );
  }

  getVisitors(
    code: string,
    pagination: Pagination
  ): Observable<PaginationResult<KeyValuePair[]>> {
    const params = new HttpParamsGenerator({ code, ...pagination }).generate();
    return this.http.get<PaginationResult<KeyValuePair[]>>(
      `${this.apiUrl}/visitor`,
      { params }
    );
  }
}
