import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@services/language/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'digital-factory';

  constructor(
    languageService: LanguageService,
    translateService: TranslateService
  ) {
    translateService.addLangs(languageService.getLanguages());
    translateService.use(languageService.getLanguage());
  }
}
