import { Injectable } from '@angular/core';
import { Announcement } from '@models/announcement';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ViewAnnouncementDialogService {
  private announcement = new BehaviorSubject<Announcement>(null);
  announcement$ = this.announcement.asObservable();

  visible = false;

  constructor() {}

  open(announcement: Announcement): void {
    this.announcement.next(announcement);
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }
}
