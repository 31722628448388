import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { JabilUserFormComponent } from './jabil-user-form.component';

@NgModule({
  declarations: [JabilUserFormComponent],
  imports: [
    CalendarModule,
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [JabilUserFormComponent],
})
export class JabilUserFormModule {}
