<p-dialog
  [(visible)]="presenter.visible"
  [resizable]="false"
  [draggable]="false"
  [modal]="true"
  [blockScroll]="true"
  (onHide)="onCancel()"
  styleClass="p-dialog--maxWidth80Percent logoutCountdownDialog"
>
  <ng-template pTemplate="header">
    {{ 'LOGOUT_COUNTDOWN_DIALOG__TITLE' | translate }}
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngTemplateOutlet="dialogContent"></ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <ng-container *ngTemplateOutlet="dialogFooter"></ng-container>
  </ng-template>
</p-dialog>

<ng-template #dialogContent>
  <div class="mb-2">
    <b>
      {{ 'LOGOUT_COUNTDOWN_DIALOG__INACTIVE_MESSAGE' | translate }}
      <span *ngIf="idleTimeoutInHours >= 1">
        {{ idleTimeoutInHours }}
        {{ 'LOGOUT_COUNTDOWN_DIALOG__HOUR' | translate }}
      </span>
      <span *ngIf="idleTimeoutInMinutes >= 1">
        {{ idleTimeoutInMinutes }}
        {{ 'LOGOUT_COUNTDOWN_DIALOG__MINUTES' | translate }}
      </span>
      <span *ngIf="idleTimeoutInSeconds >= 1">
        {{ idleTimeoutInSeconds }}
        {{ 'LOGOUT_COUNTDOWN_DIALOG__SECONDS' | translate }}
      </span>
      <span style="margin-left: -3px">
        {{ 'LOGOUT_COUNTDOWN_DIALOG__END_OF_MESSAGE' | translate }}
      </span>
    </b>
  </div>
  <div translate [translateParams]="{ period: presenter.count }">
    LOGOUT_COUNTDOWN_DIALOG__LOGOUT_MESSAGE
  </div>
</ng-template>

<ng-template #dialogFooter>
  <button type="button" class="btn btn-primary" (click)="onLogout()">
    {{ 'BUTTON__LOGOUT' | translate }}
  </button>
  <button class="btn btn-outline-primary" (click)="onStay()">
    {{ 'BUTTON__STAY' | translate }}
  </button>
</ng-template>
