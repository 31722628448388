import { Injectable, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { IdleService } from '@services/idle/idle.service';

const COUNTDOWN_PERIOD = 30;

@Injectable()
export class LogoutCountdownDialogService implements OnDestroy {
  visible = false;
  count = COUNTDOWN_PERIOD;
  interval;

  constructor(
    private authenticationService: AuthenticationService,
    private idleService: IdleService
  ) {}

  ngOnDestroy(): void {
    this.clearInterval();
  }

  open(): void {
    this.count = COUNTDOWN_PERIOD;
    this.setInterval();
    this.visible = true;
  }

  close(): void {
    this.clearInterval();
    this.visible = false;
  }

  stay(): void {
    this.close();
    this.idleService.start();
  }

  confirmLogout(): void {
    this.authenticationService.logout();
  }

  private setInterval(): void {
    this.interval = setInterval(() => {
      if (this.count === 0) {
        this.confirmLogout();
      }

      if (this.count <= 0) {
        this.clearInterval();
        return;
      }

      if (this.idleService.timeoutPeriod) {
        this.close();
        return;
      }

      this.count--;
    }, 1000);
  }

  private clearInterval(): void {
    if (!this.interval) {
      return;
    }
    clearInterval(this.interval);
  }
}
