import { AbstractControl, ValidationErrors } from '@angular/forms';
import { PHONE_NUMBER_REGEX } from '@utils/regex';

export function PhoneNumberValidator(
  control: AbstractControl
): ValidationErrors {
  const value = control.value;

  if (!value) {
    return;
  }

  const isValidPhoneNumber = PHONE_NUMBER_REGEX.test(value);
  const atLeastEightDigits = value.match(/\d/g)?.length >= 8;

  if (!atLeastEightDigits) {
    return { minEightDigits: true };
  }

  if (!isValidPhoneNumber) {
    return { invalidPhoneNumber: true };
  }

  return null;
}
