import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoadingUiBlockerModule } from '@components/loading-ui-blocker/loading-ui-blocker.module';
import { UiNgModule } from '@jabil/ui-ng';
import { MainLayoutComponent } from './main-layout.component';

@NgModule({
  declarations: [MainLayoutComponent],
  imports: [CommonModule, LoadingUiBlockerModule, RouterModule, UiNgModule],
  exports: [MainLayoutComponent],
})
export class MainLayoutModule {}
