import { AbstractControl, ValidationErrors } from '@angular/forms';

const INVALID_DATE_RANGE = { invalidDateRange: true };

export function DateRangeValidator(control: AbstractControl): ValidationErrors {
  const value = control.value;

  if (!Array.isArray(value) || value.length !== 2) {
    return INVALID_DATE_RANGE;
  }

  if (!value[0]) {
    return INVALID_DATE_RANGE;
  }

  if (!value[1]) {
    return INVALID_DATE_RANGE;
  }

  return null;
}
