<p-dialog
  [(visible)]="presenter.visible"
  [resizable]="false"
  [draggable]="false"
  [modal]="true"
  [blockScroll]="true"
  (onHide)="onCancel()"
  styleClass="p-dialog--maxWidth80Percent helpDialog"
>
  <ng-template pTemplate="header">
    <div>{{ 'MENU__HELP' | translate }}</div>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngTemplateOutlet="dialogContent"></ng-container>
  </ng-template>
</p-dialog>

<ng-template #dialogContent>
  <main class="helpDialog__body">
    <aside class="helpDialog__tableOfContent">
      <div
        class="helpDialog__tableOfContentItem"
        [ngClass]="
          currentTab === 1 ? 'helpDialog__tableOfContentItem--active' : null
        "
        (click)="onChangeTab(1)"
      >
        {{ 'MENU__CONTENT' | translate }}
      </div>
      <div
        class="helpDialog__tableOfContentItem"
        [ngClass]="
          currentTab === 2 ? 'helpDialog__tableOfContentItem--active' : null
        "
        (click)="onChangeTab(2)"
      >
        {{ 'MENU__SCHEDULER' | translate }}
      </div>
      <div
        class="helpDialog__tableOfContentItem"
        [ngClass]="
          currentTab === 3 ? 'helpDialog__tableOfContentItem--active' : null
        "
        (click)="onChangeTab(3)"
      >
        {{ 'MENU__USER_MANAGEMENT' | translate }}
      </div>
      <div
        class="helpDialog__tableOfContentItem"
        [ngClass]="
          currentTab === 4 ? 'helpDialog__tableOfContentItem--active' : null
        "
        (click)="onChangeTab(4)"
      >
        {{ 'MENU__LMS' | translate }}
      </div>
      <div
        class="helpDialog__tableOfContentItem"
        [ngClass]="
          currentTab === 5 ? 'helpDialog__tableOfContentItem--active' : null
        "
        (click)="onChangeTab(5)"
      >
        {{ 'MENU__OTHERS' | translate }}
      </div>
    </aside>
    <section class="helpDialog__content" [ngSwitch]="currentTab">
      <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="content">
      </ng-container>
      <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="scheduler">
      </ng-container>
      <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="userManagement">
      </ng-container>
      <ng-container *ngSwitchCase="4" [ngTemplateOutlet]="lms"></ng-container>
      <ng-container *ngSwitchCase="5" [ngTemplateOutlet]="others">
      </ng-container>
    </section>
  </main>
</ng-template>

<ng-template #content>
  <ol>
    <li>
      <header>{{ 'HELP__CONTENT_QUESTION_1' | translate }}</header>
      <p>{{ 'HELP__CONTENT_QUESTION_1_PARAGRAPH_1' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__CONTENT_QUESTION_2' | translate }}</header>
      <p>{{ 'HELP__CONTENT_QUESTION_2_PARAGRAPH_1' | translate }}</p>
      <p>
        {{ 'HELP__CONTENT_QUESTION_2_PARAGRAPH_2.1' | translate }}<br />
        {{ 'HELP__CONTENT_QUESTION_2_PARAGRAPH_2.2' | translate }}
      </p>
      <p>{{ 'HELP__CONTENT_QUESTION_2_PARAGRAPH_3' | translate }}</p>
      <p>{{ 'HELP__CONTENT_QUESTION_2_PARAGRAPH_4' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__CONTENT_QUESTION_3' | translate }}</header>
      <p>{{ 'HELP__CONTENT_QUESTION_3_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__CONTENT_QUESTION_3_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__CONTENT_QUESTION_3_PARAGRAPH_3' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__CONTENT_QUESTION_4' | translate }}</header>
      <p>{{ 'HELP__CONTENT_QUESTION_4_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__CONTENT_QUESTION_4_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__CONTENT_QUESTION_4_PARAGRAPH_3' | translate }}</p>
      <p>{{ 'HELP__CONTENT_QUESTION_4_PARAGRAPH_4' | translate }}</p>
      <p>{{ 'HELP__CONTENT_QUESTION_4_PARAGRAPH_5' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__CONTENT_QUESTION_5' | translate }}</header>
      <p>{{ 'HELP__CONTENT_QUESTION_5_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__CONTENT_QUESTION_5_PARAGRAPH_2' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__CONTENT_QUESTION_6' | translate }}</header>
      <p>{{ 'HELP__CONTENT_QUESTION_6_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__CONTENT_QUESTION_6_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__CONTENT_QUESTION_6_PARAGRAPH_3' | translate }}</p>
      <p>
        <b>{{ 'HELP__CONTENT_QUESTION_6_PARAGRAPH_4.1' | translate }}</b>
        {{ 'HELP__CONTENT_QUESTION_6_PARAGRAPH_4.2' | translate }}
      </p>
      <p>
        <b>{{ 'HELP__CONTENT_QUESTION_6_PARAGRAPH_5.1' | translate }}</b>
        {{ 'HELP__CONTENT_QUESTION_6_PARAGRAPH_5.2' | translate }}
      </p>
    </li>
  </ol>
</ng-template>

<ng-template #scheduler>
  <ol>
    <li>
      <header>{{ 'HELP__SCHEDULER_QUESTION_1' | translate }}</header>
      <p>{{ 'HELP__SCHEDULER_QUESTION_1_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__SCHEDULER_QUESTION_1_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__SCHEDULER_QUESTION_1_PARAGRAPH_3' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__SCHEDULER_QUESTION_2' | translate }}</header>
      <p>{{ 'HELP__SCHEDULER_QUESTION_2_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__SCHEDULER_QUESTION_2_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__SCHEDULER_QUESTION_2_PARAGRAPH_3' | translate }}</p>
      <p>{{ 'HELP__SCHEDULER_QUESTION_2_PARAGRAPH_4' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__SCHEDULER_QUESTION_3' | translate }}</header>
      <p>{{ 'HELP__SCHEDULER_QUESTION_3_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__SCHEDULER_QUESTION_3_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__SCHEDULER_QUESTION_3_PARAGRAPH_3' | translate }}</p>
    </li>
  </ol>
</ng-template>

<ng-template #userManagement>
  <ol>
    <li>
      <header>{{ 'HELP__USER_MANAGEMENT_QUESTION_1' | translate }}</header>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_1_PARAGRAPH_1' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__USER_MANAGEMENT_QUESTION_2' | translate }}</header>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_2_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_2_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_2_PARAGRAPH_3' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__USER_MANAGEMENT_QUESTION_3' | translate }}</header>
      <p>
        <b>{{
          'HELP__USER_MANAGEMENT_QUESTION_3_PARAGRAPH_1.1' | translate
        }}</b>
        {{ 'HELP__USER_MANAGEMENT_QUESTION_3_PARAGRAPH_1.2' | translate }}
      </p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_3_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_3_PARAGRAPH_3' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_3_PARAGRAPH_4' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_3_PARAGRAPH_5' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_3_PARAGRAPH_6' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_3_PARAGRAPH_7' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__USER_MANAGEMENT_QUESTION_4' | translate }}</header>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_4_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_4_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_4_PARAGRAPH_3' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_4_PARAGRAPH_4' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__USER_MANAGEMENT_QUESTION_5' | translate }}</header>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_5_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__USER_MANAGEMENT_QUESTION_5_PARAGRAPH_2' | translate }}</p>
    </li>
  </ol>
</ng-template>

<ng-template #lms>
  <ol>
    <li>
      <header>{{ 'HELP__LMS_QUESTION_1' | translate }}</header>
      <p>{{ 'HELP__LMS_QUESTION_1_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__LMS_QUESTION_1_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__LMS_QUESTION_1_PARAGRAPH_3' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__LMS_QUESTION_2' | translate }}</header>
      <p>{{ 'HELP__LMS_QUESTION_2_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__LMS_QUESTION_2_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__LMS_QUESTION_2_PARAGRAPH_3' | translate }}</p>
      <p>{{ 'HELP__LMS_QUESTION_2_PARAGRAPH_4' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__LMS_QUESTION_3' | translate }}</header>
      <p>{{ 'HELP__LMS_QUESTION_3_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__LMS_QUESTION_3_PARAGRAPH_2' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__LMS_QUESTION_4' | translate }}</header>
      <p>{{ 'HELP__LMS_QUESTION_4_PARAGRAPH_1' | translate }}</p>
      <p>{{ 'HELP__LMS_QUESTION_4_PARAGRAPH_2' | translate }}</p>
      <p>{{ 'HELP__LMS_QUESTION_4_PARAGRAPH_3' | translate }}</p>
    </li>
  </ol>
</ng-template>

<ng-template #others>
  <ol>
    <li>
      <header>{{ 'HELP__OTHERS_QUESTION_1' | translate }}</header>
      <p>{{ 'HELP__OTHERS_QUESTION_1_PARAGRAPH_1' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__OTHERS_QUESTION_2' | translate }}</header>
      <p>{{ 'HELP__OTHERS_QUESTION_2_PARAGRAPH_1' | translate }}</p>
    </li>

    <li>
      <header>{{ 'HELP__OTHERS_QUESTION_3' | translate }}</header>
      <p>{{ 'HELP__OTHERS_QUESTION_3_PARAGRAPH_1' | translate }}</p>
    </li>
  </ol>
</ng-template>
