export interface UserSetting {
  id: number;
  userId: number;
  settingKey: string;
  settingValue: string;
  dataType: string;
  concurrencyToken: number;
}

export enum UserSettingKey {
  LANGUAGE = 'LANGUAGE',
  TIMEZONE = 'TIMEZONE',
}
