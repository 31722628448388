<p-dialog
  [(visible)]="presenter.visible"
  [resizable]="false"
  [draggable]="false"
  [modal]="true"
  [blockScroll]="true"
  (onHide)="onCancel()"
  styleClass="p-dialog--maxWidth80Percent announcementDialog"
>
  <ng-template pTemplate="header">
    <div>
      {{ 'MENU__ANNOUNCEMENT' | translate }}
      <span
        *ngIf="formGroup.value.status === STATUS.DRAFT"
        class="announcementDialog__status ml-3"
      >
        {{ formGroup.value.status }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngTemplateOutlet="dialogContent"></ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <ng-container *ngTemplateOutlet="dialogFooter"></ng-container>
  </ng-template>
</p-dialog>

<ng-template #dialogContent>
  <form class="mb-4" [formGroup]="formGroup">
    <section class="announcementDialog__formGroup">
      <label>{{ 'ANNOUNCEMENT__TITLE' | translate }}</label>
      <input class="form-control" formControlName="title" />
    </section>
    <section class="announcementDialog__formGroup">
      <label>{{ 'ANNOUNCEMENT__DESCRIPTION' | translate }}</label>
      <textarea class="form-control" formControlName="description"></textarea>
    </section>
  </form>
  <section class="mb-3" *ngIf="presenter.publishedAnnouncement$ | async">
    <div class="mb-2">{{ 'ANNOUNCEMENT__PUBLISHED_MESSAGE' | translate }}</div>
    <div class="announcementDialog__previousAnnouncement">
      <div class="mb-1">
        {{ (presenter.publishedAnnouncement$ | async).title }}
      </div>
      <div>
        {{ (presenter.publishedAnnouncement$ | async).description }}
      </div>
    </div>
  </section>
</ng-template>

<ng-template #dialogFooter>
  <button type="button" class="btn btn-outline-primary" (click)="onCancel()">
    {{ 'BUTTON__CANCEL' | translate }}
  </button>
  <button class="btn btn-primary" (click)="onSave()">
    {{ 'BUTTON__SAVE' | translate }}
  </button>
  <button class="btn btn-primary" (click)="onPublish()">
    {{ 'BUTTON__PUBLISH' | translate }}
  </button>
</ng-template>
