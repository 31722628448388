import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { JabilUserFormModule } from '@components/jabil-user-form/jabil-user-form.module';
import { MenuModule as AppMenuModule } from '@components/menu/menu.module';
import { AccessRightDirectiveModule } from '@directives/access-right/access-right.module';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RelativeDatePipeModule } from 'src/app/pipes/relative-date/relative-date.module';
import { MainLayoutModule } from '../main-layout/main-layout.module';
import { AnnouncementDialogComponent } from './components/announcement-dialog/announcement-dialog.component';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';
import { LanguageDialogComponent } from './components/language-dialog/language-dialog.component';
import { LogoutCountdownDialogComponent } from './components/logout-countdown-dialog/logout-countdown-dialog.component';
import { MyProfileDialogComponent } from './components/my-profile-dialog/my-profile-dialog.component';
import { NotificationOverlayPanelComponent } from './components/notification-overlay-panel/notification-overlay-panel.component';
import { TimezoneDialogComponent } from './components/timezone-dialog/timezone-dialog.component';
import { ViewAnnouncementDialogComponent } from './components/view-announcement-dialog/view-announcement-dialog.component';
import { JabilUserLayoutComponent } from './jabil-user-layout.component';

@NgModule({
  declarations: [
    AnnouncementDialogComponent,
    JabilUserLayoutComponent,
    HelpDialogComponent,
    LanguageDialogComponent,
    LogoutCountdownDialogComponent,
    MyProfileDialogComponent,
    NotificationOverlayPanelComponent,
    TimezoneDialogComponent,
    ViewAnnouncementDialogComponent,
  ],
  imports: [
    AccessRightDirectiveModule,
    AppMenuModule,
    CommonModule,
    DialogModule,
    DropdownModule,
    JabilUserFormModule,
    FlexLayoutModule,
    MainLayoutModule,
    MenuModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    RelativeDatePipeModule,
    TranslateModule,
  ],
  exports: [JabilUserLayoutComponent],
})
export class JabilUserLayoutModule {}
