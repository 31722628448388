import { Injectable } from '@angular/core';

const LANGUAGES = ['en', 'zh', 'zh-tw'];
const DEFAULT_LANGUAGE = 'en';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private _token = 'language';
  private _selectedLanguage: string;

  constructor() {
    this.setDefaultLanguage();
  }

  private setDefaultLanguage(): void {
    const selectedLanguage = this.getLanguage();
    if (!!selectedLanguage) {
      return;
    }
    this.setLanguage(DEFAULT_LANGUAGE);
  }

  getLanguages(): string[] {
    return LANGUAGES;
  }

  getLanguage(): string {
    const language =
      this._selectedLanguage || localStorage.getItem(this._token);
    return language;
  }

  setLanguage(language: string): void {
    if (!LANGUAGES.find((lang) => lang === language)) {
      console.warn('Invalid language.');
      return;
    }
    this._selectedLanguage = language;
    localStorage.setItem(this._token, language);
  }
}
