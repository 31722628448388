import { InjectionToken } from '@angular/core';

export interface EnvironmentConfig {
  environment: {
    baseUrl: string;
    baseMagnoliaPath: string;
    cloudExpoUrl: string;
    idlePeriodInSeconds: number;
    displayEnvironment?: string;
  };
}

export const ENV_CONFIG = new InjectionToken<EnvironmentConfig>(
  'EnvironmentConfig'
);
