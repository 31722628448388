import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RelativeDatePipe } from './relative-date.pipe';

@NgModule({
  declarations: [RelativeDatePipe],
  imports: [CommonModule],
  exports: [RelativeDatePipe],
})
export class RelativeDatePipeModule {}
