<form [formGroup]="formGroup">
  <div class="userSection">
    <!-- <div class="userSection__formGroup">
      <label class="required">
        {{ 'JABIL_USER__OKTA_USERNAME' | translate }}
      </label>
      <input
        type="text"
        class="form-control"
        formControlName="userName"
        [readonly]="isEditMode || isEditSelf"
      />
    </div> -->
    <div class="userSection__formGroup">
      <label class="required">
        {{ 'JABIL_USER__NAME' | translate }}
      </label>
      <input type="text" class="form-control" formControlName="name" />
    </div>
    <div class="userSection__formGroup">
      <label>{{ 'JABIL_USER__COMPANY_WEBSITE' | translate }}</label>
      <div class="userSection__formControl">
        <input
          type="text"
          class="form-control"
          formControlName="companyWebsite"
        />
        <div class="invalid-feedback">
          {{ 'JABIL_USER__COMPANY_WEBSITE_INVALID' | translate }}
        </div>
      </div>
    </div>
    <div class="userSection__formGroup">
      <label class="required">{{ 'JABIL_USER__EMAIL' | translate }}</label>
      <div class="userSection__formControl">
        <input
          type="text"
          class="form-control"
          formControlName="email"
          [readonly]="isEditSelf"
        />
        <div class="invalid-feedback">
          <span *ngIf="formGroupControls.email?.errors?.required">
            {{ 'JABIL_USER__EMAIL_REQUIRED' | translate }}
          </span>
          <span *ngIf="formGroupControls.email?.errors?.email">
            {{ 'JABIL_USER__EMAIL_INVALID' | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="userSection__formGroup">
      <label class="required">
        {{ 'JABIL_USER__COMPANY_NAME' | translate }}
      </label>
      <input type="text" class="form-control" formControlName="companyName" />
    </div>
    <div class="userSection__formGroup">
      <label class="required">
        {{ 'JABIL_USER__PHONE_NUMBER' | translate }}
      </label>
      <div class="userSection__formControl">
        <input type="text" class="form-control" formControlName="phoneNumber" />
        <div class="invalid-feedback">
          <span *ngIf="formGroupControls.phoneNumber?.errors?.required">
            {{ 'VISITOR__PHONE_NUMBER_IS_REQUIRED' | translate }}
          </span>
          <span *ngIf="formGroupControls.phoneNumber?.errors?.minEightDigits">
            {{ 'VISITOR__PHONE_NUMBER_INSUFFICIENT_CHARACTER' | translate }}
          </span>
          <span
            *ngIf="formGroupControls.phoneNumber?.errors?.invalidPhoneNumber"
          >
            {{ 'VISITOR__PHONE_NUMBER_INVALID_CHARACTER' | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="userSection__formGroup">
      <label>{{ 'JABIL_USER__POSTCODE' | translate }}</label>
      <input type="text" class="form-control" formControlName="postalCode" />
    </div>
    <div class="userSection__formGroup">
      <label class="required">
        {{ 'JABIL_USER__PREFERRED_LANGUAGE' | translate }}
      </label>
      <p-dropdown
        class="form-control"
        [options]="languages"
        [placeholder]="'JABIL_USER__SELECT_A_LANGUAGE' | translate"
        optionLabel="name"
        optionValue="id"
        formControlName="preferredLanguageId"
      ></p-dropdown>
    </div>
    <div class="userSection__formGroup">
      <label class="required">{{ 'JABIL_USER__COUNTRY' | translate }}</label>
      <p-dropdown
        class="form-control"
        [options]="countries"
        [placeholder]="'JABIL_USER__SELECT_A_COUNTRY' | translate"
        [filter]="true"
        optionLabel="name"
        optionValue="id"
        formControlName="countryId"
      ></p-dropdown>
    </div>
    <div class="userSection__formGroup">
      <label class="required">
        {{ 'JABIL_USER__EFFECTIVE_DATES' | translate }}
      </label>
      <p-calendar
        class="form-control p-calendar--fullWidth"
        formControlName="effectiveDates"
        selectionMode="range"
        yearRange="2010:2099"
        [monthNavigator]="true"
        [yearNavigator]="true"
        [showIcon]="true"
        [disabled]="isEditSelf"
      ></p-calendar>
    </div>
    <div class="userSection__formGroup">
      <label>{{ 'JABIL_USER__OKTA_VERIFIED' | translate }}</label>
      <ng-container [ngSwitch]="this.formGroupValues.oktaVerified">
        <div class="userSection__oktaVerified--true" *ngSwitchCase="true">
          <span class="material-icons">verified_user</span>
          <span>{{ 'JABIL_USER__OKTA_VERIFIED_USER' | translate }}</span>
        </div>
        <div class="userSection__oktaVerified" *ngSwitchDefault>
          <span class="material-icons">privacy_tip</span>
          <span>{{ 'JABIL_USER__OKTA_NOT_VERIFIED_USER' | translate }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</form>
