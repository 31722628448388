import { Injectable, OnDestroy } from '@angular/core';
import { NotificationApiService } from '@api/notification/notification.api';
import { Notification } from '@models/notification';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { promptMessageOnError } from '@utils/rxjs/prompt-message-on-error';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class NotificationService implements OnDestroy {
  private _notifications = new BehaviorSubject<Notification[]>([]);
  notifications$ = this._notifications.asObservable();

  private getNotificationsSubscription: Subscription;

  constructor(
    private messageService: MessageService,
    private notificationApi: NotificationApiService,
    private translateService: TranslateService
  ) {
    this.get();
  }

  ngOnDestroy(): void {
    // Reserve for `untilDestroyed()` operator.
  }

  get(): void {
    if (
      this.getNotificationsSubscription &&
      !this.getNotificationsSubscription.closed
    ) {
      this.getNotificationsSubscription.unsubscribe();
    }
    const title = this.translateService.instant(
      'NOTIFICATION__GET_NOTIFICATIONS_FAILED'
    );
    this.getNotificationsSubscription = this.notificationApi
      .get()
      .pipe(
        map(({ data }) => data),
        tap((notifications) => this._notifications.next(notifications)),
        promptMessageOnError(this.messageService, title),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
