export interface CurrentUserDetails {
  id: number;
  applicationUserId: number;
  name: string;
  userName: string;
  email: string;
  phoneNumber: string;
  type: UserType;
  roleId: number;
  roleName: string;
  language: string;
  timezone: string;
  timezoneOffset: string;
}

export enum UserType {
  VISITOR = 'VISITOR',
  INTERNAL_VISITOR = 'INTERNAL_VISITOR',
  JABIL_USER = 'JABIL_USER',
}
