import { HttpParams } from '@angular/common/http';

type KeyValuePair = [string, any];

export class HttpParamsGenerator {
  value: object;
  private keyValue: KeyValuePair[] = [];

  constructor(value: object) {
    if (this.isNullOrUndefined(value)) {
      console.warn('Value cannot be empty.');
      return;
    }

    if (!this.isObject(value)) {
      console.warn('Invalid property type.');
      return;
    }

    this.value = value;
  }

  generate(): HttpParams {
    const httpParams = new HttpParams();

    if (this.isNullOrUndefined(this.value)) {
      console.warn('Value cannot be null or undefined.');
      return httpParams;
    }

    this.generateKeyValue();

    if (this.keyValue.length === 0) {
      return httpParams;
    }

    return this.appendKeyValueToHttpParams(httpParams);
  }

  private appendKeyValueToHttpParams(httpParams: HttpParams): HttpParams {
    this.keyValue.forEach(([key, value]) => {
      if (this.isDate(value)) {
        httpParams = httpParams.append(key, value.toISOString());
        return;
      }
      httpParams = httpParams.append(key, value);
    });
    return httpParams;
  }

  private generateKeyValue(): void {
    const keys = Object.keys(this.value);
    keys.forEach((key) => this.addKeyValue([key, this.value[key]]));
  }

  private addKeyValue([key, value]: KeyValuePair): void {
    // if value is null, undefined or empty string, skip process.
    if (this.isNullOrUndefined(value) || this.isEmptyString(value)) {
      return;
    }

    // if value is array, append key with index.
    if (this.isArray(value)) {
      value.forEach((subValue: any, index: number) => {
        this.addKeyValue([`${key}[${index}]`, subValue]);
      });
      return;
    }

    // if value is object, append key with . follow with nested key.
    if (this.isObject(value)) {
      const keys = Object.keys(value);
      keys.forEach((subKey) =>
        this.addKeyValue([`${key}.${subKey}`, value[subKey]])
      );
      return;
    }

    // otherwise, push modified key and value to the list.
    this.keyValue.push([key, value]);
  }

  private isObject(value: any): boolean {
    return (
      typeof value === 'object' &&
      !this.isNullOrUndefined(value) &&
      !this.isDate(value) &&
      !this.isArray(value)
    );
  }

  private isEmptyString(value: string): boolean {
    return value === '';
  }

  private isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }

  private isArray(value: any[]): boolean {
    return Array.isArray(value);
  }

  private isDate(value: Date): boolean {
    return typeof value === 'object' && value instanceof Date;
  }
}
