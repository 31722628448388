import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LoginResponse } from '@models/api/account';
import { EnvironmentConfig, ENV_CONFIG } from '@utils/http/environment-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountApiService {
  private apiUrl: string;
  private applicationUserApiUrl: string;

  constructor(
    @Inject(ENV_CONFIG)
    private envConfig: EnvironmentConfig,
    private http: HttpClient
  ) {
    this.apiUrl = `${envConfig.environment.baseUrl}/connect/token`;
    this.applicationUserApiUrl = `${envConfig.environment.baseUrl}/api/application-user`;
  }

  login(username: string, password: string): Observable<LoginResponse> {
    const body = new HttpParams()
      .set('email', username)
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password')
      .set('scope', 'offline_access')
      .set('client_id', 'DF2Admin')
      .set('client_secret', 'Qn5uOYx0S9DWOm87qNV4rwEJWPCGl6fRWmBv4pfsqDM=');

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<LoginResponse>(this.apiUrl, body, { headers });
  }

  logout(): void {
    window.location.replace(`${this.applicationUserApiUrl}/logout`);
  }

  contextSwitch(token: string): Observable<LoginResponse> {
    const body = new HttpParams()
      .set('token', token)
      .set('grant_type', 'context-switch')
      .set('client_id', 'DF2Admin');

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<LoginResponse>(this.apiUrl, body, { headers });
  }
}
