import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MenuModule as AppMenuModule } from '@components/menu/menu.module';
import { MenuModule } from 'primeng/menu';
import { MainLayoutModule } from '../main-layout/main-layout.module';
import { VisitorLayoutComponent } from './visitor-layout.component';

@NgModule({
  declarations: [VisitorLayoutComponent],
  imports: [
    CommonModule,
    AppMenuModule,
    CommonModule,
    FlexLayoutModule,
    MainLayoutModule,
    MenuModule,
  ],
  exports: [VisitorLayoutComponent],
})
export class VisitorLayoutModule {}
