import { EventEmitter, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { addSeconds } from 'date-fns';
import { fromEvent, interval, merge, Subscription } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const DEFAULT_INTERVAL = 500;
const LOCAL_STORAGE_KEY = 'expiry';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class IdleService implements OnDestroy {
  idleEvent = new EventEmitter();

  private timeoutInSeconds = environment.idlePeriodInSeconds;
  private idleEventSubscription: Subscription;
  private eventsSubscription: Subscription;

  get timeoutPeriod(): Date {
    const timeoutPeriod = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!timeoutPeriod) {
      return null;
    }
    return new Date(Number(timeoutPeriod));
  }

  private set _timeoutPeriod(timeoutPeriod: Date) {
    const timeoutPeriodString = timeoutPeriod.getTime().toString();
    localStorage.setItem(LOCAL_STORAGE_KEY, timeoutPeriodString);
  }

  constructor() {
    const expiry = this.getTimeoutFromLocalStorage();
    if (!expiry) {
      return;
    }
    this._timeoutPeriod = expiry;
  }

  ngOnDestroy(): void {
    // Reserve for `untilDestroyed()` operator.
  }

  start(): void {
    if (this.timeoutInSeconds === null) {
      return;
    }

    if (this.eventsSubscription && !this.eventsSubscription.closed) {
      this.eventsSubscription.unsubscribe();
    }

    const timeoutPeriod = addSeconds(new Date(), this.timeoutInSeconds);
    this.updateTimeoutPeriod(timeoutPeriod);
    this.createIdleEvent();

    const keydown$ = fromEvent(document, 'keydown');
    const mousemove$ = fromEvent(document, 'mousemove');
    const mouseup$ = fromEvent(document, 'mouseup');
    const mousedown$ = fromEvent(document, 'mousedown');
    const scroll$ = fromEvent(document, 'scroll');

    const events = merge(keydown$, mousemove$, mouseup$, mousedown$, scroll$)
      .pipe(
        tap(() => this.postponeIdleEvent()),
        untilDestroyed(this)
      )
      .subscribe();
    this.eventsSubscription = events;
  }

  stop(): void {
    this.removeEventListener();
    this.removeIdleEvent();
    this.removeTimeoutPeriod();
  }

  private createIdleEvent(): void {
    if (this.idleEventSubscription && !this.idleEventSubscription.closed) {
      this.idleEventSubscription.unsubscribe();
    }
    const idleEventSubscription = interval(DEFAULT_INTERVAL)
      .pipe(
        filter(() => !!this.timeoutPeriod),
        delay(this.timeoutPeriod),
        tap(() => {
          if (this.timeoutPeriod > new Date()) {
            this.createIdleEvent();
            return;
          }
          this.idleEvent.emit();
          this.stop();
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this.idleEventSubscription = idleEventSubscription;
  }

  private getTimeoutFromLocalStorage(): Date {
    const timeout = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (!timeout) {
      return;
    }
    return new Date(Number(timeout));
  }

  private postponeIdleEvent(): void {
    if (!this.timeoutPeriod) {
      return;
    }
    const timeoutPeriod = addSeconds(new Date(), this.timeoutInSeconds);
    this.updateTimeoutPeriod(timeoutPeriod);

    this.removeIdleEvent();
    this.createIdleEvent();
  }

  private removeIdleEvent(): void {
    if (!this.idleEventSubscription || this.idleEventSubscription.closed) {
      return;
    }
    this.idleEventSubscription.unsubscribe();
  }

  private removeTimeoutPeriod(): void {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

  private removeEventListener(): void {
    if (!this.eventsSubscription || this.eventsSubscription.closed) {
      return;
    }
    this.eventsSubscription.unsubscribe();
  }

  private updateTimeoutPeriod(timeoutPeriod: Date): void {
    this._timeoutPeriod = timeoutPeriod;
  }
}
