<app-main-layout [menuTemplate]="menuTemplate"></app-main-layout>

<ng-template #menuTemplate>
  <img
    src="assets/images/jabil-logo-white.svg"
    class="visitorLayout__companyLogo ml-4 mr-4"
    routerLink="/view/learn"
  />
  <div fxFlex>
    <app-menu [menuItems]="mainMenus"></app-menu>
  </div>
  <!-- <button class="btn">{{ userService.userDetails?.name }}</button> -->
  <!-- <button class="btn" (click)="menu.toggle($event)">
    <i class="fas fa-cog"></i>
  </button>
  <p-menu #menu [popup]="true" [model]="settingMenus"></p-menu> -->
</ng-template>
