<p-dialog
  [(visible)]="presenter.visible"
  [resizable]="false"
  [draggable]="false"
  [modal]="true"
  [blockScroll]="true"
  (onHide)="onCancel()"
  styleClass="p-dialog--maxWidth80Percent myProfileDialog"
>
  <ng-template pTemplate="header">
    <div>
      {{ 'MENU__MY_PROFILE' | translate }}
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <ng-container *ngTemplateOutlet="dialogContent"></ng-container>
  </ng-template>
  <ng-template pTemplate="footer">
    <ng-container *ngTemplateOutlet="dialogFooter"></ng-container>
  </ng-template>
</p-dialog>

<ng-template #dialogContent>
  <app-jabil-user-form
    [countries]="presenter.countries$ | async"
    [isEditMode]="true"
    [isEditSelf]="true"
    [languages]="presenter.languages$ | async"
    [user]="presenter.user$ | async"
  ></app-jabil-user-form>
</ng-template>

<ng-template #dialogFooter>
  <button type="button" class="btn btn-outline-primary" (click)="onCancel()">
    {{ 'BUTTON__CANCEL' | translate }}
  </button>
  <button class="btn btn-primary" (click)="onSave()">
    {{ 'BUTTON__SAVE' | translate }}
  </button>
</ng-template>
