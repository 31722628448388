<app-main-layout [menuTemplate]="menuTemplate"></app-main-layout>

<ng-template #menuTemplate>
  <img
    src="assets/images/jabil-logo-white.svg"
    class="jabilUserLayout__companyLogo ml-4 mr-4"
    routerLink="/"
  />
  <div class="jabilUserLayout__menu" fxFlex>
    <app-menu [menuItems]="mainMenus"></app-menu>
  </div>
  <button
    id="btnCloudExpo"
    class="btn"
    appAccessRight="CloudExpo"
    (click)="onContextSwitch()"
  >
    <img
      class="jabilUserLayout__cloudExpoImage"
      src="/assets/images/cloud-expo.svg"
    />
  </button>
  <div class="jabilUserLayout__userName">
    {{ userService.userDetails?.name }}
  </div>
  <button class="btn" (click)="openNotificationPanel($event)">
    <i class="fas fa-bell"></i>
  </button>
  <button class="btn" (click)="menu.toggle($event)">
    <i class="fas fa-cog"></i>
  </button>
  <p-menu #menu [popup]="true" [model]="settingMenus"></p-menu>
  <button class="btn" (click)="onHelp()">
    <i class="fas fa-question-circle"></i>
  </button>
  <div class="jabilUserLayout__version">v{{ version }}</div>
  <div class="jabilUserLayout__environment">
    <span *ngIf="displayEnvironment" class="mr-2">
      {{ displayEnvironment }}
    </span>
  </div>
</ng-template>

<!-- Dialogs -->
<app-announcement-dialog></app-announcement-dialog>
<app-help-dialog></app-help-dialog>
<app-language-dialog></app-language-dialog>
<app-logout-countdown-dialog></app-logout-countdown-dialog>
<app-my-profile-dialog></app-my-profile-dialog>
<app-timezone-dialog></app-timezone-dialog>
<app-view-announcement-dialog></app-view-announcement-dialog>

<!-- Overlay panel -->
<app-notification-overlay-panel
  #notificationPanel
  [announcement]="announcementService.publishedAnnouncement$ | async"
  [notifications]="notificationService.notifications$ | async"
  (announcementClick)="onViewAnnouncement($event)"
></app-notification-overlay-panel>
