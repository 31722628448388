import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.authenticationService.accessToken) {
      return next.handle(req);
    }

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authenticationService.accessToken}`,
      },
    });

    return next.handle(req).pipe(
      catchError((error: HttpResponse<any>) => {
        if (error?.status === 401) {
          this.authenticationService.logout();
        }
        throw error;
      })
    );
  }
}
