import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ApplicationUserApiService } from '@api/application-user/application-user.api';
import { Pagination } from '@models/api';
import { AuthorizationService } from '@services/authorization/authorization.service';
import { promptMessageOnError } from '@utils/rxjs/prompt-message-on-error';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const DEFAULT_PAGINATION: Pagination = {
  current: 1,
  pageSize: 1000,
  total: null,
};

@Injectable({
  providedIn: 'root',
})
export class GetAccessRightGuard implements CanActivate {
  constructor(
    private api: ApplicationUserApiService,
    private authorizationService: AuthorizationService,
    private messageService: MessageService
  ) {}

  canActivate(): Observable<boolean> {
    const title = 'Failed to get client access rights';
    return this.api.currentAccessRights(DEFAULT_PAGINATION, null, null).pipe(
      map(({ data }) => data.data),
      tap((accessRights) =>
        this.authorizationService.updateAccessRights(accessRights)
      ),
      map((accessRights) => !!accessRights),
      promptMessageOnError(this.messageService, title)
    );
  }
}
