import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EnvironmentConfig, ENV_CONFIG } from './environment-config';
import { AuthInterceptor } from './http-interceptor/auth-interceptor.service';
import { LoaderInterceptor } from './http-interceptor/loader-interceptor.service';

@NgModule()
export class HttpModule {
  static forRoot(
    environmentConfig: EnvironmentConfig
  ): ModuleWithProviders<HttpModule> {
    return {
      ngModule: HttpModule,
      providers: [
        {
          provide: ENV_CONFIG,
          useValue: environmentConfig,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true,
        },
      ],
    };
  }
}
