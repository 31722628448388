import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Announcement } from '@models/announcement';
import { Notification } from '@models/notification';
import { TimezoneService } from '@services/timezone/timezone.service';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-notification-overlay-panel',
  templateUrl: './notification-overlay-panel.component.html',
  styleUrls: ['./notification-overlay-panel.component.scss'],
})
export class NotificationOverlayPanelComponent {
  @Input()
  announcement: Announcement;

  @Input()
  notifications: Notification[];

  @Output()
  announcementClick = new EventEmitter<Announcement>();

  @ViewChild(OverlayPanel)
  overlayPanel: OverlayPanel;

  isShowAllNotification = false;

  get isVisible(): boolean {
    return this.overlayPanel.overlayVisible;
  }

  constructor(public timezoneService: TimezoneService) {}

  toggle(event: MouseEvent): void {
    if (!this.overlayPanel) {
      return;
    }
    this.isShowAllNotification = false;
    this.overlayPanel.toggle(event);
  }

  onAnnouncementClick(announcement: Announcement): void {
    this.overlayPanel.hide();
    this.announcementClick.emit(announcement);
  }

  onNotificationClick(notification: Announcement): void {
    this.overlayPanel.hide();
  }

  onReadAllNotification(): void {
    this.isShowAllNotification = true;
  }
}
