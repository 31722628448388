import { Pipe, PipeTransform } from '@angular/core';
import { toSentenceCase } from '@utils/text';
import {
  differenceInCalendarDays,
  format,
  formatDistance,
  formatRelative,
  parseISO,
  startOfWeek,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

@Pipe({
  name: 'relativeDate',
})
export class RelativeDatePipe implements PipeTransform {
  transform(value: string | Date, ...args: string[]): unknown {
    if (!value) {
      return;
    }

    if (typeof value === 'string' && !parseISO(value)) {
      return value;
    }

    const date = this.parseDate(value);
    const today = new Date();

    if (differenceInCalendarDays(today, date) < -3) {
      return toSentenceCase(formatDistance(date, today, { addSuffix: true }));
    }

    if (startOfWeek(today) < date) {
      return toSentenceCase(formatRelative(date, today, { weekStartsOn: 1 }));
    }

    const timeZone = args[0];
    return format(utcToZonedTime(date, timeZone), 'd MMM yyyy p');
  }

  private parseDate(value: string | Date): Date {
    if (typeof value === 'string') {
      return new Date(value);
    }
    return value;
  }
}
