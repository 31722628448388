import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthorizationService } from '@services/authorization/authorization.service';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccessRightGuard implements CanActivate {
  constructor(
    private authorizationService: AuthorizationService,
    private messageService: MessageService,
    private router: Router
  ) {}

  private isFirstTime = true;

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const accessRight = route.data.accessRight;

    if (!accessRight) {
      console.error('No access right provided for the access right guard.');
      return false;
    }

    const validateResult = this.authorizationService.validate(accessRight);

    if (validateResult === false) {
      if (route.data.redirectTo) {
        this.navigateOnUnauthorized(route);
        return;
      }

      if (this.isFirstTime) {
        this.isFirstTime = false;
        return;
      }
      this.promptUnauthorizedMessage();
    }

    return validateResult;
  }

  private promptUnauthorizedMessage(): void {
    this.messageService.add({
      severity: 'warn',
      summary: 'Unauthorized access',
    });
  }

  private navigateOnUnauthorized(route: ActivatedRouteSnapshot): void {
    if (!route.data.redirectTo) {
      return;
    }
    this.router.navigateByUrl(route.data.redirectTo);
  }
}
